import React from "react";
import "./Button.scss";
import Spinner from "../../spinner";

export default ({ label, type, onClick, icon, iconLast, disabled, loading }) => {
  const style = type ? `btn-primary ${type}` : "btn-primary";
  return (
    <button className={style} onClick={() => onClick()} disabled={disabled}>
      {icon && !iconLast && <i className={`fas fa-${icon}`}></i>}
      
      <span>{label} </span>
      { loading ?
        <Spinner type="success" spi="true" style={{width:"1rem", height:"1rem", "margin-left": "4px"}} />
        : icon && iconLast && <i className={`fas fa-${icon}`}></i>
      }
    </button>
  );
};
