import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainPopupView from '../../views/MainPopupView'
import { userActions } from '../../state/redux/user'


const mapStateToProps = state => ({
    load: state.user.load,
})

const mapDispatchToProps = dispatch => ({
    userActions : bindActionCreators(userActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainPopupView)
