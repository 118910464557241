import { createRequestTypes } from '../../../utils/parse'

const namespace = 'items'


const GET_ITEMS = createRequestTypes(`${namespace}/GET_ITEMS`)
const UPLOAD_ITEM = createRequestTypes(`${namespace}/UPLOAD`)
const SELECT_ITEM = `${namespace}/SELECT_ITEM`
const CLEAR_ITEM = `${namespace}/CLEAR_ITEM`
const CREATE_OR_UPDATE = createRequestTypes(`${namespace}/CREATE_OR_UPDATE`)
const DELETE_ITEM = createRequestTypes(`${namespace}/DELETE_ITEM`)
const GET_TYPES = createRequestTypes(`${namespace}/GET_TYPES`)
const ADD_VALUE = `${namespace}/ADD_VALUE`
const SELECT_TYPE =  `${namespace}/SELECT_TYPE`
const CLEAR_TYPE = `${namespace}/CLEAR_TYPE`
export default {
    GET_ITEMS,
    SELECT_ITEM,
    CLEAR_ITEM,
    CREATE_OR_UPDATE,
    DELETE_ITEM,
    ADD_VALUE,
    GET_TYPES,
    SELECT_TYPE,
    UPLOAD_ITEM,
    CLEAR_TYPE
}
