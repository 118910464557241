const engine = require('store/src/store-engine');

const storages = [
  require('store/storages/localStorage'),
];

const plugins = [
  require('store/plugins/defaults')
];

const store = engine.createStore(storages, plugins);

export function setKey(key, value) {
  store.set(key, value);
}

export function getKey(key) {
  return store.get(key);
}

export function removeKey(key) {
  return store.remove(key);
}
