import { spawn } from 'redux-saga/effects';
import session from './session'
import user from './user'
import items from './item'
import contact from './contactUs'
import calculator from './calculator'
const saga = function* saga () {
  yield spawn(session)
  yield spawn(user)
  yield spawn(items)
  yield spawn(contact)
  yield spawn(calculator)
}

export default saga
