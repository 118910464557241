import {takeEvery, call, put, spawn, select} from 'redux-saga/effects';

import { push } from 'react-router-redux'
import Api from '../../services/api';
import {contactUsTypes} from '../redux/contact_us'

const {GET_CONTACTUS,    CREATE_OR_UPDATE, CALL, GET_CALL} = contactUsTypes

/*
  CREATE OR UPDATE CALL
*/
function* callContactUsIndexApiCall(action){
  try{
    console.log("HERREEE")
    let result = yield call(Api.call, action.payload)
    yield put({type:CALL.SUCCESS, payload: result.data})
  }catch (e) {

  }
}

function* callContactUsIndexSaga(){
  yield takeEvery(CALL.REQUEST, callContactUsIndexApiCall)  
}


/*
  CREATE OR UPDATE ITEM
*/
function* createOrUpdateContactUsIndexApiCall(action){
    try{
      let result = yield call(Api.createOrUpdateContact, action.payload)
      yield put({type:CREATE_OR_UPDATE.SUCCESS, payload: result.data})
      yield put({type: GET_CONTACTUS.REQUEST})
    }catch (e) {
  
    }
}
  
function* createOrUpdateContactUsIndexSaga(){
    yield takeEvery(CREATE_OR_UPDATE.REQUEST, createOrUpdateContactUsIndexApiCall)  
}
/*  
    GET CONTACT
*/

function* getContactUsIndexApiCall(action){
    try{
      let result = yield call(Api.getContactUs)
      yield put({type:GET_CONTACTUS.SUCCESS, payload: result.data})  
    }catch (e) {
  
    }
  }
  
function* getContactUsIndexSaga(){
    yield takeEvery(GET_CONTACTUS.REQUEST, getContactUsIndexApiCall)  
}

/*  
    GET CONTACT
*/

function* getCallIndexApiCall(action){
  try{
    let result = yield call(Api.getCall)
    yield put({type:GET_CALL.SUCCESS, payload: result.data})  
  }catch (e) {

  }
}

function* getCallIndexSaga(){
  yield takeEvery(GET_CALL.REQUEST, getCallIndexApiCall)  
}
  

function* userIndexSaga () {
    yield spawn(getContactUsIndexSaga)
    yield spawn(createOrUpdateContactUsIndexSaga)
    yield spawn(callContactUsIndexSaga)
    yield spawn(getCallIndexSaga)

}
  
  export default userIndexSaga
  