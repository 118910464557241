import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../../../../components/common/components/Modal'
import {  contactUsSelectors, contactUsActions} from '../../../../../state/redux/contact_us'
import {  calculatorSelectors} from '../../../../../state/redux/calculator'
const { getValues} = calculatorSelectors
const { isLoad} = contactUsSelectors

const mapStateToProps = state => ({
    isLoad: isLoad(state),
    payload: getValues(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(contactUsActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal)
