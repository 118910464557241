/*
  REDUCER SESSION
 */
import types from './types';
import { combineReducers} from 'redux';

const initialState = {
  isLogin: false,
  user: {},
  load: false,
  error:false,
  messageError:null
}


const session = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN.SUCCESS:
      return {
        ...state,
        user: {
            ...state.user,
            ...action.payload
          },
          error:false,
          messageError:null,
          isLogin: true,
          load: false
      }
      case types.RESTART:
      case types.LOGOUT.SUCCESS:
        return initialState
      case types.GET_USER.SUCCESS:
        return {
          ...state,
          isLogin: true,
            load: false,
            user: {
              ...state.user,
              ...action.payload
            }
        }
      case types.UPDATE_USER.SUCCESS:
        return {
          ...state,
          user: {
              ...state.user,
              ...action.payload
            },
            load: false
        }
      case types.CHANGE_USER:
        return {
            ...state,
            user: {
                ...state.user,
                ...action.payload
              }
      }
      case types.LOGIN.FAILURE:
        return{
          ...state,
          error:true,
          isLogin: false,
          load: false,
          messageError:action.payload.error
        }
      case types.LOGIN.REQUEST:
      case types.LOGOUT.REQUEST:
      case types.GET_USER.REQUEST:
      case types.UPDATE_USER.REQUEST:
        return {
          ...state,
          load: true
        }
      default:
        return state
  }
}


export default session
