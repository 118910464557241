import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserProfileLite from '../../views/UserProfileLite'
import { sessionActions, sessionSelectors} from '../../state/redux/session'
import { userActions, userSelectors} from '../../state/redux/user'

const { getUser} = sessionSelectors
const{getUserSelected, loadAvatar, uploadImage} = userSelectors

const mapStateToProps = state => ({
  user: getUser(state),
  userSelected : getUserSelected(state),
  loadAvatar: loadAvatar(state),
  uploadImage: uploadImage(state)
})

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  userActions : bindActionCreators(userActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileLite)
