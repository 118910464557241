import React from 'react'
import {
    Card,
    CardHeader,
    CardTitle,
    CardImg,
    CardBody,
    CardFooter,
    Button
  } from "shards-react";
const Box = ({item}) => {
    return (
        <Card small className="width-box" >
            <CardBody>
                <div className="img-width-box" ><img src={item.src} /></div>
                <p>{item.title}</p>
                <span>{item.text}</span>

        </CardBody>
        </Card>
    )
}

export default Box