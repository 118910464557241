import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultLayout from '../../layouts/Default'
import { sessionActions, sessionSelectors} from '../../state/redux/session'
import {sideNavBarActions} from '../../state/redux/siderNavBar'
const {isLogin, isLoadSession, getUser} = sessionSelectors

const mapStateToProps = state => ({
  isLogin: isLogin(state),
  isLoadSession: isLoadSession(state),
  user: getUser(state)
})

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  sideNavBarActions: bindActionCreators(sideNavBarActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultLayout)
