export default function() {
    return [
      {
        title: "Home",
        id: "home",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/"
      },
      {
        title: "Servicios",
        id: "service",
        htmlBefore: '<i class="material-icons">note_add</i>',
        to: "/services"
      },
      {
        title: "Nosotros",
        id: "we",
        htmlBefore: '<i class="material-icons">note_add</i>',
        to: "/about-us"
      },
      {
        title: "Contacto",
        id: "contact",
        // classItem: "btn-header",
        htmlBefore: '<i class="material-icons">note_add</i>',
        to: "/contact"
      },
      {
        title: "Iniciar sesión",
        id: "login",
        htmlBefore: '<i class="material-icons">note_add</i>',
        to: "/admin"
      },
      {
        title: "Calculador",
        id: "calculator",
        htmlBefore: '<i class="material-icons">note_add</i>',
        to: "/calculator"
      }
    ];
  }
