import React, { Component } from "react";
import "./image-upload.css";
import Spinner from "../common/spinner"
class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;

    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      let response = files.item(0)
      if (this.props.multiple){
        response = this.fileListToArray(files);
      }
      this.props.onFilesAdded(response);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      let response = files.item(0)
      if (this.props.multiple){
        response = this.fileListToArray(files);
      }
      this.props.onFilesAdded(response);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    let { src, imgClass, dropClass, multiple, loadAvatar } = this.props


    return (
      <div
        className={`dropzone ${this.state.hightlight ? "highlight" : ""} ${dropClass}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <input
          ref={this.fileInputRef}
          className="fileInput"
          type="file"
          multiple={multiple || false}
          onChange={this.onFilesAdded}
        />
        <img
          alt="upload"
          className={`${imgClass ? imgClass : 'icon'} upload-image`}
          src={src}
        />
        {
          loadAvatar ?
            <div className="middle full">
              <Spinner></Spinner>
            </div>
          :
          <div className="middle">
            <img src="/upload.svg"/>
          </div>
        }
        


      </div>
    );
  }
}

export default ImageUpload;
