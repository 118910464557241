import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    ButtonGroup,
    Button,
    Row,
    Form,
    Col,
    FormInput,
    FormTextarea,
    FormFeedback
    } from "shards-react";
const FormHowTo = ({item, onSubmit, onChange, onClear, error})=> (
    <Card small className="blog-comments mb-2">
            
    <CardBody className="">
    
      <Form>
        <Row form>
          {/* Pregnta frecuente */}
          <Col md="12" className="form-group">
            <label htmlFor="title">Titulo</label>
            <FormInput
              id="title"
              name="title"
              value={item ? item.title : ""}
              placeholder="Titulo"
              onChange={onChange}
              invalid={error.title}
              required

            />
            <FormFeedback>Se requiere un Title</FormFeedback>
          </Col>
          {/* Respuesta a pregunta */}
          <Col md="12" className="form-group">
            <label htmlFor="description">Descripción</label>
            <FormTextarea
              id="description"
              name="description"
              placeholder="Coloque una descripción."
              value={item ? item.description : ""}
              required
              invalid={error.description}
              onChange={onChange}
            />
            <FormFeedback>Se requiere una Respueta.</FormFeedback>
          </Col>
        </Row>
        
          {/* Content :: Actions */}
          <div className="m-1 f-r blog-comments__actions">
              
              <ButtonGroup size="sm">
                <Button theme="white" onClick={() => {onClear()}}>
                  <span className="text-light">
                      <i className="material-icons">clear</i>
                  </span>{" "}
                  Clear
                  </Button>
                  <Button theme="white" onClick={() => {onSubmit()}}>
                  <span className="text-light">
                      <i className="material-icons">edit</i>
                  </span>{" "}
                  {item.itemId ? 'Actualizar' : 'Crear'}
                  </Button>
              </ButtonGroup>
          </div>
      </Form>
    </CardBody>

</Card>
)

export default FormHowTo