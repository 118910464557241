import React, {Component} from 'react'
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Row,
  Col,
  Container,
} from "shards-react";

import Map from '../components/common/map';
import {Contatusform} from '../components/contact-us';
import PageTitle from "../components/common/PageTitle";
import LoadScripted from "../components/common/LoadScripted"



class ContactUsView extends Component{
    constructor(props){
        super(props)

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.check = this.check.bind(this)
        this.state ={
            item:{
                email:"",
            },
            error:{
                email:false,
            },
            load: false
        }
    }

    componentDidMount () {
        let {contactUsActions} = this.props
        contactUsActions.getContact()
        contactUsActions.addValue(this.state.item)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contact != this.props.contact){
            this.props.contactUsActions.addValue(this.props.contact)
        }
    }

    onChange (e) {
        let {contactUsActions} = this.props
        contactUsActions.addValue({[e.target.name]: e.target.value}) 
    }

    check(){
        let {edit} = this.props
        let error ={email:null}

        if (edit.email === ""){
            error.title=true
        }else {
            error.title=false
        }
        this.setState({error})
        if (error.email) return false
        return true
    }
    onSubmit () {
            
        if (this.check()){
            let {contactUsActions,edit} = this.props
            contactUsActions.createOrUpdate({...edit})
        }
    }

    render(){
        let {edit} = this.props
        console.log(edit)
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Contactenos" subtitle="" className="text-sm-left" />
                </Row>
                <Row>
                <LoadScripted>
                    <React.Fragment>
                        <Col xs={6}>
                            <Contatusform item={edit} error={this.state.error} onChange={this.onChange} onSubmit={this.onSubmit} />
                        </Col>
                        <Col xs={6}>
                            <Map
                                google={this.props.google}
                                center={{lat: 18.5204, lng: 73.8567}}
                                height='300px'
                                zoom={15}
                                />
                        </Col>
                    </React.Fragment>
                </LoadScripted>
                    
                </Row>

                    
            </Container>

           )
    }
}

export default ContactUsView