import React from "react";
import "./Faq.scss";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "90%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

export default (props) => {
  console.log("QUESTIONS", props)
  let questions = props.questions
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <section className="faq">
      <div className="faq__titles">
        <h3>FAQs</h3>
        <h2>Preguntas frecuentes</h2>
      </div>
      <div className="faq__questions">
        <div className={classes.root}>
          {questions.map((panel, panelKey) => (
            <ExpansionPanel
              expanded={expanded === "panel" + panelKey}
              onChange={handleChange("panel" + panelKey)}
              key={`panel${panelKey}`}
              className="faq__head-wrapper"
            >
              <ExpansionPanelSummary
                expandIcon={
                  expanded === "panel" + panelKey ? <RemoveIcon /> : <AddIcon />
                }
                aria-controls="panel1bh-content"
                className="faq__panel-head"
              >
                <Typography className={classes.heading}>
                  {panel.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="faq__panel-content">
                {panel.description.split('\n').map((line, lineKey) => (
                  <p key={`line${lineKey}`}>{line}</p>
                ))}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>
      </div>
    </section>
  );
};
