import { createRequestTypes } from '../../../utils/parse'

const namespace = 'user'

const UPLOAD_AVATAR = createRequestTypes(`${namespace}/UPLOAD_AVATAR`)
const GET_USERS = createRequestTypes(`${namespace}/GET_USERS`)
const CREATE_OR_UPDATE_USER = createRequestTypes(`${namespace}/CREATE_OR_UPDATE_USER`)
const CHANGE_PASSWORD = createRequestTypes(`${namespace}/CHANGE_PASSWORD`)
const DELETE_USER = createRequestTypes(`${namespace}/DELETE_USER`)
const SELECT_USER = `${namespace}/SELECT_USER`
const CLEAR_SELECT_USER = `${namespace}/CLEAR_SELECT_USER`
const LOAD_AVATAR = `${namespace}/LOAD_AVATAR`
const GET_USERS_FILES = createRequestTypes(`${namespace}/GET_USERS_FILES`)
const UPLOAD_FILE = createRequestTypes(`${namespace}/UPLOAD_FILE`)
const DOWNLOAD_FILE = `${namespace}/DOWNLOAD_FILE`
const DELETE_FILE = `${namespace}/DELETE_FILE`
const UPLOAD_POPUP_IMAGE = createRequestTypes(`${namespace}/UPLOAD_POPUP_IMAGE`)

export default{
  GET_USERS,
  CREATE_OR_UPDATE_USER,
  CHANGE_PASSWORD,
  SELECT_USER,
  DELETE_USER,
  CLEAR_SELECT_USER,
  UPLOAD_AVATAR,
  LOAD_AVATAR,
  GET_USERS_FILES,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  DELETE_FILE,
  UPLOAD_POPUP_IMAGE,
}
