import React from "react"
import PropTypes from "prop-types"
import moment from 'moment-timezone'
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import {FormControl, MenuItem, Select} from "@material-ui/core";

class UserAccountDetails extends React.Component {
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange(e){
    let {onChange} = this.props
    let payload = {[e.target.name]: e.target.value}
    onChange(payload)
  }
  onSubmit(){
    let {actions, userDetails}= this.props
    actions.updateUserRequest({user: userDetails})

  }

  render(){
    let {userDetails, actions, newUser} = this.props
    return(
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Detalle de cuenta</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form>
                    <Row form>
                      {/* First Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feFirstName">Nombre</label>
                        <FormInput
                            id="feFirstName"
                            name="name"
                            defaultValue={userDetails.name}
                            placeholder="Nombre"
                            onChange={this.onChange}
                        />
                      </Col>
                      {/* Last Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="felastname">Apellido</label>
                        <FormInput
                            id="felastname"
                            name="lastname"
                            placeholder="Last Name"
                            defaultValue={userDetails.lastname}
                            onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      {/* First Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feDNI">Documento</label>
                        <FormInput
                            id="feDNI"
                            name="dni"
                            type="number"
                            defaultValue={userDetails.dni}
                            placeholder="Documento"
                            onChange={this.onChange}
                        />
                      </Col>
                      {/* Last Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feBirthday">Fecha Nacimiento</label>
                        <FormInput
                            id="feBirthday"
                            name="birthDay"
                            type="date"
                            placeholder="dd/mm/yyyy"
                            defaultValue={moment(new Date(userDetails.birthDay)).tz('America/Argentina/Rio_Gallegos').format('YYYY-MM-DD')}
                            onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col md={newUser ? '6': '12'} className="form-group">
                        <label htmlFor="feEmail">Email</label>
                        <FormInput
                            type="email"
                            name="email"
                            id="feEmail"
                            placeholder="Email"
                            defaultValue={userDetails.email}
                            onChange={this.onChange}
                        />
                      </Col>
                      <Col md={newUser ? '6': '12'} className="form-group">
                        <label htmlFor="feUsername">Nombre de usuario</label>
                        <FormInput
                            name="username"
                            id="feUsername"
                            placeholder="Usuario"
                            defaultValue={userDetails.username}
                            onChange={this.onChange}
                        />
                      </Col>
                      <Col md={newUser ? '6': '12'} className="form-group">
                        <label htmlFor="feCuit">CUIT</label>
                        <FormInput
                            name="cuit"
                            id="feCuit"
                            placeholder="CUIT"
                            defaultValue={userDetails.cuit}
                            onChange={this.onChange}
                        />
                      </Col>
                      {/* Password */}
                      {
                        newUser ?
                            <Col md="6" className="form-group">
                              <label htmlFor="fePassword">Password</label>
                              <FormInput
                                  id="fePassword"
                                  placeholder="Password"
                                  type="password"
                                  name="password"
                                  onChange={this.onChange}
                              />
                            </Col>
                            :''
                      }
                    </Row>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="feAddress">Dirección</label>
                        <FormInput
                            id="feAddress"
                            placeholder="Dirección"
                            name="address"
                            defaultValue={userDetails.address}
                            onChange={this.onChange}
                        />
                      </Col>
                      <Col md="6" className="form-group">
                        <label htmlFor="fePhone">Télefono</label>
                        <FormInput
                            type="tel"
                            id="fePhone"
                            name="phone"
                            placeholder="Télefono"
                            defaultValue={userDetails.phone}
                            onChange={this.onChange}
                            autoComplete="current-password"
                        />
                      </Col>
                    </Row>
                    { (newUser || userDetails.role === 'ADMIN') && (
                        <Row form>
                          <Col md="6" className="form-group">
                            <label htmlFor="feRole">Rol</label>
                            <FormControl fullWidth id="feRole">
                              <Select
                                  onChange={this.onChange}
                                  name="role"
                                  value={userDetails.role || "0"}
                              >
                                <MenuItem value="0" disabled>Seleccione un tipo usuario</MenuItem>
                                <MenuItem value="ADMIN">ADMIN</MenuItem>
                                <MenuItem value="PARTNER">PARTNER</MenuItem>
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                    )}
                    <Row form>
                      {/* Description */}
                      <Col md="12" className="form-group">
                        <label htmlFor="feDescription">Datos Adicionales</label>
                        <FormTextarea id="feDescription" rows="5" name="extra" onChange={this.onChange} defaultValue={userDetails.extra} />
                      </Col>
                    </Row>
                    <Button theme="accent" onClick={this.onSubmit}>Submit</Button>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
    )
  }

}

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
