import React from 'react'
import {
    Card,
    CardBody,
    ButtonGroup,
    Button,
  } from "shards-react";
const BoxHowTo = ({item, edit, onDelete, onEdit}) => {
    return (
        <div className="bg-how-to" >
            <div className="title-how-to" >{item.title}</div>
            <div className="description-how-to" >{item.description}</div>


            {edit ?
            <div className="m-1 f-r blog-comments__actions mt-2">
                <ButtonGroup size="sm" className="">
                    
                    <Button theme="white" onClick={() => {onDelete(item.itemId)}}>
                    <span className="text-danger">
                        <i className="material-icons">clear</i>
                    </span>{" "}
                    Eliminar
                    </Button>
                    <Button theme="white" onClick={() => {onEdit(item.itemId)}}>
                    <span className="text-light">
                        <i className="material-icons">more_vert</i>
                    </span>{" "}
                    Editar
                    </Button>
                </ButtonGroup>
            </div> 
            :""}
        </div>
    )
}

export default BoxHowTo