import React, { useEffect, useState } from "react";
import { Container, Row } from "shards-react";
import Header from "../containers/layout/HeaderContainer";
import Footer from "../containers/layout/footerContainer";
import "../assets/landing.css";
const LandingLayout = ({ children, itemsSorts }) => {
  const [navActive, setNavActive] = useState(false);
  useEffect(() => {
    window.onscroll = function() {
      if (window.pageYOffset === 0) {
        setNavActive(false);
      }else{
        setNavActive("nav-scroll");
      }
    };
  }, [navActive]);

  return (
    <>
      <Row className={`fixed-100 ${navActive}`}>
        <Header navActive={navActive} />
      </Row>
      <Container fluid>
        <Row>{React.cloneElement(children, { itemsSorts: itemsSorts })}</Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </>
  );
};

export default LandingLayout;
