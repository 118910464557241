import React, { useState } from "react";
import * as Scroll from "react-scroll";
import Fade from "react-reveal/Fade"; 
import CalculatorStep0 from "../CalculatorForm/CalculatorStep0";
import CalculatorStep1 from "../CalculatorForm/CalculatorStep1";
import CalculatorStep2 from "../CalculatorForm/CalculatorStep2";
import CalculatorStep3 from "../CalculatorForm/CalculatorStep3";
import CalculatorStep4 from "../CalculatorForm/CalculatorStep4";
import CalculatorStep5 from "../CalculatorForm/CalculatorStep5";
import CalculatorStep6 from "../CalculatorForm/CalculatorStep6";
import CalculatorStep7 from "../CalculatorForm/CalculatorStep7";
import CalculatorStep8 from "../CalculatorForm/CalculatorStep8";
import CalculatorStep9 from "../CalculatorForm/CalculatorStep9";
import CalculatorStepResult from "../CalculatorForm/CalculatorStepResult";
import CalculatorStepSaving from "../CalculatorForm/CalculatorStepSaving";
import Modal from "../../../containers/landings/component/common/Modal";
import ModalSuccess from "../../common/components/Modal/ModalSuccess";
import "./CalculatorBanner.scss";

let Element = Scroll.Element;

export default (props) => {

  let {
    calculatorActions,
    loading,
    validate,
    payload,
    calculate,
    history
  } = props


  const bannerImg = "./images/calculator/banner.png";
  const lastStep = 11;
  const initialState = {
    cuit: "",
    rubro: "",
    activo_total: "",
    pasivo_total: "",
    facturacion_anual: "",
    impuesto_ganancias: "",
    ultimo_ejercicio: "",
    email: ""
  };
  const [step, setStep] = useState(1);
  const [formInfo, setFormInfo] = useState(initialState);
  const [saving, setSaving] = useState(null);
  const [saved, setSaved] = useState(null);
  const [sended, setSended] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);

  const resetForm = () => {
    setFormInfo(initialState);
    setSaved(null);
    setSended(null);
    setStep(1);
  }



  const nextStep = step => {

    let next = parseInt(step) + 1;
 
    return next > lastStep ? lastStep : next;
  };
  const prevStep = step => {
    
    let prev = parseInt(step) - 1;
    
    return prev < 1 ? 1 : prev;
  };
  const goHome = () => {
    history.push("/")
  }

  

  const sendForm = () => {
      setSaving(true);
      setTimeout(() => {
        setSaved(true);
        setSaving(null);
        setStep(11);
      }, 3000);
    }
    
    const validStyleStep = (step, current) => {
      let style = '';
      step === current
      ? (style = " current-step ")
      : step > current
      ? (style = " prev-step ")
      : (style = " next-step ");
      return style
    }

    const setValue = (value) => {
     
      // calculatorActions.setValues(value)
    }

    
    const allSteps = [1, 2 , 3, 4, 5, 6, 7, 8, 9];
    let formStepsProps = {
    setStep,
    nextStep,
    prevStep,
    step,
    validate,
    calculatorActions,
    loading,
    payload,
    calculate,
    setFormInfo: setValue,
    formInfo,
    formStepStyle: ''
  }
  
  return (
    <>
      <section
        className="banner-calculator"
        style={{ backgroundImage: "url(" + bannerImg + ")" }}
      >
        <Fade top cascade>
          <div className="form-container">
            <CalculatorStep0
              title1=" Simulá ahora "
              title2=" tu límite crediticio "
              subtitle=" Te ayudamos a encontrar la mejor solución financiera para tu empresa. "
              action={{
                label: "COMENZAR",
                type: "bordered",
                onClick: () => setStep(nextStep(step))
              }}
              resetForm={resetForm}
            />
          </div>
        </Fade>
      </section>
      <div className="calculator-spy__block"></div>
      <Element name="step1" className="calculator-spy"></Element>
      <section className="calculator">
        <div className="calculator-bg"></div>
        <div className="calculator__container">
          {allSteps.map(stepItem => {
            switch (stepItem) {
              case 1:
                let formStepsProps1 = formStepsProps;
                formStepsProps1.formStepStyle = validStyleStep(step, 1);
                return <CalculatorStep1 {...formStepsProps1} />;

              case 2:
                formStepsProps.formStepStyle = validStyleStep(step, 2);
                return <CalculatorStep2 {...formStepsProps} />;

              case 3:
                formStepsProps.formStepStyle = validStyleStep(step, 3);
                return <CalculatorStep3 {...formStepsProps} />;

              case 4:
                formStepsProps.formStepStyle = validStyleStep(step, 4);
                return <CalculatorStep4 {...formStepsProps} />;

              case 5:
                formStepsProps.formStepStyle = validStyleStep(step, 5);
                return <CalculatorStep5 {...formStepsProps} />;

              case 6:
                formStepsProps.formStepStyle = validStyleStep(step, 6);
                return <CalculatorStep6 {...formStepsProps} />;

              case 7:
                formStepsProps.formStepStyle = validStyleStep(step, 7);
                return <CalculatorStep7 {...formStepsProps} />;

              case 8:
                formStepsProps.formStepStyle = validStyleStep(step, 8);
                return <CalculatorStep8 {...formStepsProps} />;
              case 9:
                formStepsProps.nextStep = sendForm;
                formStepsProps.formStepStyle = validStyleStep(step, 9);
                return <CalculatorStep9 {...formStepsProps} />;

              default:
                return false;
            }
          })}
          {saving && <CalculatorStepSaving />}
          {step === 11 && (
            <CalculatorStepResult
              result={calculate.response}
              openModal={openModal}
              setOpenModal={setOpenModal}
              goHome={goHome}
            />
          )}
        </div>
      </section>
      <section class={`legal ${saved && "saved"}`}>
        <p>
          AVISO LEGAL: El servicio de simulación de aval no constituye una
          oferta para comprar/vender ningún instrumento financiero aquí
          mencionado. Se han tomado los recaudos para asegurar que la
          información contenida en el mismo sea contable. Ni el Grupo RIG ni el
          personal que elaboró este reporte son responsables por cualquier daño
          producido por su utilización. Ni el Grupo RIG ni ninguna otra entidad
          garantizan de ningún modo el resultado o rendimiento de las
          inversiones. Los rendimientos pasados no son garantía de rendimientos
          futuros. Este documento es entregado para el exclusivo uso del
          destinatario. Queda prohibida su distribución y/o publicación total o
          parcial sin el expreso consentimiento del Grupo RIG.
        </p>
      </section>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setOpenModalSuccess={setOpenModalSuccess}
      />
      <ModalSuccess
        openModalSuccess={openModalSuccess}
        setOpenModalSuccess={setOpenModalSuccess}
        goHomeP={goHome}
      />
    </>
  );
};
