import React from "react";
import Contact from "../components/landing/Contact";

class ContacView extends React.Component {
  constructor(props){
    super(props)
  }
  componentDidMount () {
    let {contactUsActions, contact} = this.props
    if (contact == null){
      contactUsActions.getContact()
    }
  }

  render(){
    let {contact, isLoad, contactUsActions} = this.props
    
    console.log("contact: ",contact)
    return (
      <>
        <div className="contact-wrapper-margin">
          <Contact  actions={contactUsActions} isLoad={isLoad} contact={contact} />
        </div>
      </>
    )
  }
}

export default ContacView
