import React from "react";
import "./AboutArticle.scss";

export default ({ title, content, action }) => (
  <section className="about-article">
    {title && <h2>{title}</h2>}
    {content && <p>{content}</p>}
    {action.label && action.onClick && <h4 onClick={() => action.onClick()}>{action.label}</h4>}
  </section>
);
