import types from './types'

const getItems = (payload) => ({
    type: types.GET_ITEMS.REQUEST,
    payload
 })
const createOrUpdate = (payload) => ({
    type: types.CREATE_OR_UPDATE.REQUEST,
    payload
})

const getTypes = ()=> ({
    type: types.GET_TYPES.REQUEST,
 })

const selectType = (payload)=> ({
    type: types.SELECT_TYPE,
    payload
})

const selectItem = (payload) => ({
    type: types.SELECT_ITEM,
    payload
})
const clearItem = () => ({
    type: types.CLEAR_ITEM,
 })

const clearType = () => ({
    type: types.CLEAR_TYPE,
})

const deleteItem = (payload) => ({
    type: types.DELETE_ITEM.REQUEST,
    payload
})

const addValue = (payload) => ({
    type: types.ADD_VALUE,
    payload
})

const uploadImage = (payload) => ({
    type:types.UPLOAD_ITEM.REQUEST,
    payload
})

export default{
    getItems,
    createOrUpdate,
    selectItem,
    clearItem,
    deleteItem,
    addValue,
    getTypes,
    selectType,
    clearType,
    uploadImage
}