import React, { useState, useEffect, useRef } from "react";
import "./style.css";

export default function PopupHome() {
  const [isVisible, setIsVisible] = useState(true);
  const host = `https://s3.amazonaws.com/${process.env.NODE_ENV === 'production' ? 'tuaval.image.files.com' : 'dev.tuaval.image.files.com'}`;

  function toggleVisibility() {
    setIsVisible(!isVisible);
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleVisibility();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef);
  return (
    <div className={`popup-wrapper ${isVisible ? "" : "hide"}`}>
      <div className="popup-container">
        <div className="popup">
          <button onClick={toggleVisibility} className="close-button">
            X
          </button>
          <picture className="popup-image" ref={wrapperRef}>
            <source
              srcset={`${host}/popup-mobile.jpg`}
              media="(max-width: 768px)"
            />
            <img src={`${host}/popup.jpg`} alt="Popup" />
          </picture>
        </div>
      </div>
    </div>
  );
}
