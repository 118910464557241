import React, { Component } from "react";
import { useLoadScript } from "@react-google-maps/api";
import Spinner from "./spinner";
const LoadScripted = ({ children }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyD8UV0lLrPWOs4bs44cGzVkW8qQyKRiXt8",
    libraries: ["places"],
  });

  return (
    <React.Fragment>
      {isLoaded ? (
        <React.Fragment>{children ? children : "VACIO"}</React.Fragment>
      ) : (
        <Spinner />
      )}
    </React.Fragment>
  );
};
export default LoadScripted;
