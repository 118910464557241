import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserDatialView from '../../views/UserDatialView'
import {  sessionSelectors} from '../../state/redux/session'
import {userActions,  userSelectors} from '../../state/redux/user'

const { getUser} = sessionSelectors
const{getUserSelected} = userSelectors

const mapStateToProps = state => ({
  user: getUser(state),
  userSelected : getUserSelected(state)
})

const mapDispatchToProps = dispatch => ({
    userActions : bindActionCreators(userActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDatialView)
