import React, { Component } from "react";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse
} from "shards-react";
import { Link } from "react-router-dom";

import './Header.scss'

class Header extends Component {
    constructor(props) {
      super(props);
  
      this.setItem = this.setItem.bind(this);
      this.toggleNavbar = this.toggleNavbar.bind(this);
  
      this.state = {
        dropdownOpen: false,
        collapseOpen: false
      };
    }
  
    setItem(item) {
        let {sideNavBarActions, history} = this.props
        sideNavBarActions.setActiveMenu(item.id)
    }
  
    toggleNavbar() {
      this.setState({
        ...this.state,
        ...{
          collapseOpen: !this.state.collapseOpen
        }
      });
    }
    render() {
        const { navActive } = this.props;
        let {navBarItems, active, history} = this.props;
        const {location: { pathname }} = history;
        const whiteNav = pathname === "/" || pathname === "/calculator";
        const logoWhite = require('../../../images/logo_white.svg');
        const logoBlue = require('../../../images/logo_blue.svg');
        const navImg = whiteNav && !navActive ? logoWhite : logoBlue;
        const homeClass = whiteNav ? "home-nav" : "";
      return (
        <Navbar
          full
          fixed
          className={`nav-bar-header ${homeClass}`}
          expand="md"
        >
          <Link to={"/"}>
            <img
              src={this.state.collapseOpen ? logoWhite : navImg}
              alt="logo"
            />
          </Link>
          <NavbarToggler
            onClick={this.toggleNavbar}
            className={this.state.collapseOpen ? "active" : ""}
          >
            <div className="toggle-icon"></div>
          </NavbarToggler>
          <Collapse open={this.state.collapseOpen} navbar>
            <Nav navbar>
              {navBarItems.map((nav, i) => (
                <NavItem>
                  <Link
                    to={nav.to}
                    active={active == nav.id}
                    className={
                      nav.classItem ? `nav-link ${nav.classItem}` : "nav-link"
                    }
                  >
                    {nav.title}
                  </Link>
                </NavItem>
              ))}
            </Nav>
          </Collapse>
        </Navbar>
      );
    }
  }

export default Header