import React from 'react'
import {Container, Row, Col} from "shards-react";

import {
    CircularProgress,
    Typography
} from '@material-ui/core';
import PageTitle from "../components/common/PageTitle";
import ImageUpload from "../components/image-upload/image-uplaod";

const DESKTOP_IMAGE_KEY = "popup.jpg"
const MOBILE_IMAGE_KEY = "popup-mobile.jpg"
class MainPopupView extends React.Component {

    constructor(props) {
        super(props);
        this.host = `https://s3.amazonaws.com/${process.env.NODE_ENV === 'production' ? 'tuaval.image.files.com' : 'dev.tuaval.image.files.com'}`;
    }


    onFilesAdded(key, file){
        const data = new FormData()
        data.append('file', file)
        data.append('key', key)

        this.props.userActions.uploadPopupImage(data)
    }

    render() {
        const { load } = this.props
        return (
            <Container fluid className="px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Popup" className="text-sm-left" />
                </Row>
                { load ? (
                    <Typography align="center" variant="subtitle2" ><CircularProgress size={68} /></Typography>
                )
                : (
                    <Row noGutters className="py-4">
                        <Col sm="6">
                            <Typography align="center" variant="subtitle2" >Imagen Desktop</Typography>
                            <ImageUpload
                                src={`${this.host}/${DESKTOP_IMAGE_KEY}?t=${new Date().getTime()}`}
                                imgClass="edit-image"
                                onFilesAdded={(file) => this.onFilesAdded(DESKTOP_IMAGE_KEY, file)}
                            />
                        </Col>
                        <Col sm="6" align="center">
                            <Typography align="center" variant="subtitle2" >Imagen Mobile</Typography>
                            <ImageUpload
                                src={`${this.host}/${MOBILE_IMAGE_KEY}?t=${new Date().getTime()}`}
                                imgClass="edit-image"
                                onFilesAdded={(file) => this.onFilesAdded(MOBILE_IMAGE_KEY, file)}
                            />
                        </Col>
                    </Row>
                )}
            </Container>
        );
    }
}


export default MainPopupView
