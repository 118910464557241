/*
  REDUCER USER
 */
import types from './types';
import sessionTypes from '../session/types';
import { combineReducers} from 'redux';
import {updateObjectInArray} from '../../../utils/parse'; // ES6
const initialState = {
  users: [],
  selectUser:null,
  load: false,
  loadAvatar:false,
  uploadImage:null,
  usersFiles: [],
}


const user = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS.SUCCESS:
      return {
        ...state,
        users: action.payload,
        selectUser:null,
        load: false
      }
    case types.SELECT_USER:
      return{
        ...state,
        selectUser: action.payload
      }
    case types.CLEAR_SELECT_USER:
      return{
        ...state,
        selectUser: null

      }
    case types.LOAD_AVATAR:
      return{
        ...state,
        loadAvatar:true
      }
    case types.UPLOAD_AVATAR.SUCCESS:
      return {
        ...state,
        users: updateObjectInArray(state.users, {'userId': action.payload.userId, 'image':action.payload.path}),
        loadAvatar:false,
        uploadImage:action.payload.path
      }

    case types.CREATE_OR_UPDATE_USER.SUCCESS:
    case types.CHANGE_PASSWORD.SUCCESS:
    case types.DELETE_USER.SUCCESS:
    case types.UPLOAD_POPUP_IMAGE.SUCCESS:
      return {
        ...state,
        load: false,
        uploadImage:null
      }
    case types.GET_USERS.REQUEST:
    case types.CREATE_OR_UPDATE_USER.REQUEST:
    case types.CHANGE_PASSWORD.REQUEST:
    case types.DELETE_USER.REQUEST:
    case types.UPLOAD_POPUP_IMAGE.REQUEST:
      return {
        ...state,
        load: true,
        uploadImage:null
      }
    case types.UPLOAD_FILE.REQUEST:
      return {
        ...state,
        uploadingFile: true,
      }
    case types.UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        uploadingFile: false,
      }
    case types.GET_USERS_FILES.SUCCESS:
      return {
        ...state,
        usersFiles: action.payload,
      }
    case sessionTypes.RESTART:
    case sessionTypes.LOGOUT.SUCCESS:
      return initialState
    default:
      return state
  }
}


export default user
