import Path from 'path'
const requestTypes = ['REQUEST', 'SUCCESS', 'FAILURE'];

export function createRequestTypes(base) {
  return requestTypes.reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}


export function updateObjectInArray(array, action) {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item
    }
  })
}

export function isEqual (value, other) {

  // Get the value type
  let type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  let valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  let otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  const compare = function (item1, item2) {

    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {

      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }

    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;

};

export function validateCuit(cuit) {
  let isValid = false;
  let rango = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  cuit = cuit.split("");

  if (cuit && cuit.length == 11) {
    let result = 0;
    for (let i = 0; i <= 9; i++) {
      result += cuit[i] * rango[i];
    }
    result = result % 11;
    result = 11 - result;

    if (result == 11) result = 0;
    if (result == 10) result = 9;

    if (result == cuit[10]) isValid = true;
  }
  return isValid;
}

export function round(num, n) {
  if (num){
    let multiplyValue = Math.pow(10, n);
    console.log(multiplyValue)
    return Math.round(num * multiplyValue) / multiplyValue;
  }else return "-"
}