import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default () => {

  return (
    <div class="step step-saving">
      <div class="step__content">
        <div className="step__content__header">
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};
