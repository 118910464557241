import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    ButtonGroup,
    Button,
    Row,
    Form,
    Col,
    FormInput,
    FormTextarea,
    FormFeedback
    } from "shards-react";
import ImageUpload from '../image-upload/image-uplaod'

const FormCategory = ({item, onSubmit, onChange, onClear, error, onFilesAdded, image}) => {
  
    return (
        <Card small className="blog-comments mb-2">
            
        <CardBody className="">
        
          <Form>
            <Row form>
              {/* Pregnta frecuente */}
              <Col md="12" className="form-group">
                <ImageUpload src={image} onFilesAdded={onFilesAdded} imgClass="edit-image" dropClass="" loadAvatar={false} />
                <FormFeedback invalid={error.image}>Se requiere una pregunta </FormFeedback>
              </Col>
              <Col md="12" className="form-group">
                <label htmlFor="title">Titulo</label>
                <FormInput
                  id="title"
                  name="title"
                  placeholder="Titulo"
                  value={item ? item.title : ""}
                  required
                  invalid={error.title}
                  onChange={onChange}
                />
                <FormFeedback>Se requiere un titulo.</FormFeedback>
              </Col>
              <Col md="12" className="form-group">
                <label htmlFor="description">Descripción</label>
                <FormInput
                  id="description"
                  name="description"
                  placeholder="Descripción"
                  value={item ? item.description : ""}
                  required
                  invalid={error.description}
                  onChange={onChange}
                />
                <FormFeedback>Se requiere una Descripción.</FormFeedback>
              </Col>
            </Row>
            
              {/* Content :: Actions */}
              <div className="m-1 f-r blog-comments__actions">
                  
                  <ButtonGroup >
                    <Button theme="white" onClick={() => {onClear()}}>
                      <span className="text-light">
                          <i className="material-icons">clear</i>
                      </span>{" "}
                      Clear
                      </Button>
                      <Button theme="white" onClick={() => {onSubmit()}}>
                      <span className="text-light">
                          <i className="material-icons">edit</i>
                      </span>{" "}
                      {item.itemId ? 'Actualizar' : 'Crear'}
                      </Button>
                  </ButtonGroup>
              </div>
          </Form>
        </CardBody>

    </Card>
    )
}

export default FormCategory 