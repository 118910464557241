import React, { Component } from "react";
import './Map.scss';

class ContactMap extends Component {
  render() {
    return (
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0492281155475!2d-58.37378584844175!3d-34.60291666486837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a33532cb59650b%3A0x1a7dac7bd5d6edc1!2sAv.%20Corrientes%20327%2C%20C1043%20AAQ%2C%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1603920287352!5m2!1ses!2sar"
          width="100%"
          height="100%"
          frameborder="0"
          style={{
            border:0,
            overflow: "hidden",
            height: "100%",
            width: "100%"
          }}
          tabindex="0"
        >
        </iframe>
    );
  }
}

export default  ContactMap;
