import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./AboutHow.scss";
import Fade from "react-reveal/Fade";
import AboutArticle from "../AboutArticle";
import CircleItem from "./CircleItem";

const AboutHow = ({ history }) => {
  const sendTo = (to) => {
    history.push(to);
    window.scrollTo(0, 0);
  };
  const title = "¿CÓMO FUNCIONA UNA SGR?";
  const content =
    "Las Sociedades de Garantía Recíproca (SGR) son organizaciones privadas que dentro del marco de la SEPYME (Secretaría dentro del Ministerio de Producción de la Nación), tienen por objeto fundamental facilitar el acceso a Créditos a las Pequeñas y Medianas Empresas Argentinas.";
  const action = {
    label: "Contactanos →",
    onClick: () => sendTo("/contact"),
  };
  const [how, setHow] = useState(0);
  const hows = [
    {
      title: "Fondo de riesgo",
      subtitle:
        "El Fondo de Riesgo da el beneficio impositivo al Socio Protector",
      icon: "group",
    },
    {
      title: "Fondo de riesgo2",
      subtitle:
        "El Fondo de Riesgo da el beneficio impositivo al Socio Protector",
      icon: "handshake",
    },
    {
      title: "Fondo de riesgo3",
      subtitle:
        "El Fondo de Riesgo da el beneficio impositivo al Socio Protector",
      icon: "dollar",
    },
    {
      title: "Fondo de riesgo4",
      subtitle:
        "El Fondo de Riesgo da el beneficio impositivo al Socio Protector",
      icon: "factory",
    },
    {
      title: "Fondo de riesgo5",
      subtitle:
        "El Fondo de Riesgo da el beneficio impositivo al Socio Protector",
      icon: "museum",
    },
  ];

  const itemsCount = hows.length || 1;

  return (
    <section className="about-how">
      <div className="column50 about-how__image">
        <Fade cascade>
          <div className="about-how__image__wrapper">
            {hows.map((item, i) => (
              <CircleItem
                active={how === i}
                index={i}
                setHow={setHow}
                itemsCount={itemsCount}
                item={item}
              />
            ))}
            <div className="circle-content">
              <div className="circle-content__in">
                <h2>{hows[how].title}</h2>
                <h4>{hows[how].subtitle}</h4>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <div className="column50 about-how__article">
        <AboutArticle title={title} content={content} action={action} />
      </div>
      <img
        className="about-how__final-image"
        src="./images/about/cat_pyme.png"
        alt="¿Cómo se categorizan las MIPyME?"
      />
    </section>
  );
};

export default withRouter(AboutHow);
