import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  contactUsSelectors, contactUsActions} from '../../state/redux/contact_us'
import FooterComponent from '../../components/layout/Landing/Footer'
const { getContactFirst, isLoad} = contactUsSelectors

const mapStateToProps = state => ({
  contact: getContactFirst(state),
  load: isLoad(state)
})

const mapDispatchToProps = dispatch => ({
    contactUsActions: bindActionCreators(contactUsActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterComponent)
