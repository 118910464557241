import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./BanksSlider.scss";
import {ClientComponent} from '../../client'
import SlideEdit from '../../slider/slider-edit'


export default class galleryItems extends Component {
  
  constructor(props){
    super(props)
    this.state ={
      
      responsive : { 450: { items: 1 }, 767: { items: 3 }, 1024: { items: 4 } }
    }
    // this.galleryItems = this.galleryItems.bind(this)

  }
  componentDidMount () {
    let {itemsActions} = this.props
    itemsActions.selectType('client')       
  }
  // galleryItems () {
  //   let {items} = this.props
  //   return items.map((item, i) => (
  //     <div className="img-wrapper" jey={`img-wrapper${i}`}>
  //       <a href = {item.link} target="_blank" >
  //         <img src={`http://tuaval.com.ar${item.image}`} alt={item.title} />
  //       </a>
  //     </div>
  //   ));
  // } 
    
  

  render () {
    let {items} = this.props
    let props =  {
      edit:false
    }
    
    return(
    <section className="about-banks">
      
      <SlideEdit items={items} classContainer="bg-client flex-align-middle" 
                        Component={ClientComponent} propsComponent={props}
                        config={{autoPlay:true, duration: 700}} responsive={this.state.responsive}
                    >
                    </SlideEdit>
      
      {/* <AliceCarousel
        dotsDisabled={true}
        buttonsDisabled={true}
        autoPlay= {false}
        playButtonEnabled={false}
        responsive={this.responsive}
        mouseDragEnabled
        items={this.galleryItems()}
        ref={el => (this.Carousel = el)}
      /> */}
    </section>
  )
  }
}
