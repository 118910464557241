import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import "./Modal.scss";

export default ({
  openModal,
  setOpenModal,
  setOpenModalSuccess,
  actions, 
  isLoad ,
  payload
}) => {
  const { register, handleSubmit, errors} = useForm();
  const [open, setOpen] = useState(false);

  const onSubmit = data => {

    actions.call({
      name: data.name,
      phone: `${data.phoneCode}-${data.phone}`,
      hour:`de ${data.dateHour1} hasta ${data.dateHour2}`,
      message:data.message,
      email:payload.email
    })
    handleClose();
    setOpenModalSuccess(true);
  };



  const handleOpen = () => {
    setOpen(true);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };

  useEffect(() => {
    if (openModal) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [openModal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal-tu-aval"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
        <Fade in={open}>
          <div className="modal-tu-aval__content">
            <h2 id="transition-modal-title">
              Dejanos tus datos y un agente te contactará a la brevedad
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-control-modal">
                <label htmlFor="name">Nombre</label>
                <input
                  className={errors.name && "input-error"}
                  type="text"
                  placeholder="Ingresá tu Nombre..."
                  name="name"
                  ref={register({
                    required: true,
                    maxLength: 80,
                    pattern: {
                      value: /[A-Z]{2,80}$/i
                    }
                  })}
                />
              </div>
              <div className="form-control-modal">
                <label htmlFor="name">Teléfono</label>
                <div className="input-wrapper">
                  <input
                    className={`phone phone-code ${
                      errors.phoneCode ? "input-error" : ""
                    }`}
                    type="text"
                    placeholder="Code"
                    name="phoneCode"
                    ref={register({
                      required: true,
                      max: 10,
                      min: 2,
                      maxLength: 10,
                      pattern: /([0-9])*/
                    })}
                  />
                  <input
                    className={`phone ${errors.phone ? "input-error" : ""}`}
                    type="text"
                    placeholder="Ingresá tu Teléfono..."
                    name="phone"
                    ref={register({
                      required: true,
                      maxLength: 15,
                      pattern: {
                        value: /([0-9])*/
                      }
                    })}
                  />
                </div>
              </div>
              <div className="form-control-modal">
                <label htmlFor="message">Mensaje</label>
                <input
                  className={errors.message && "input-error"}
                  type="text"
                  placeholder="Ingresá un mensaje"
                  name="message"
                  ref={register({
                    required: true,
                    maxLength: 80
                  })}
                />
              </div>
              <div className="form-control-modal">
                <label htmlFor="name">Horario</label>
                <div className="input-wrapper">
                  <input
                    className={`horario horario--left ${
                      errors.dateHour1 ? "input-error" : ""
                    }`}
                    type="text"
                    placeholder="17:00"
                    name="dateHour1"
                    ref={register({
                      required: true,
                      max: 5,
                      min: 1,
                      maxLength: 5,
                      pattern: /[0-9:]{1,5}/
                    })}
                  />
                  <span>o</span>
                  <input
                    className={`horario horario--right ${
                      errors.dateHour2 ? "input-error" : ""
                    }`}
                    type="text"
                    placeholder="18:30"
                    name="dateHour2"
                    ref={register({
                      required: true,
                      max: 5,
                      min: 1,
                      maxLength: 5,
                      pattern: /[0-9:]{1,5}/
                    })}
                  />
                </div>
              </div>
              <div className="modal-btn-wrapper">
                <Button className="modal-btn" type="submit">
                  Enviar
                </Button>
              </div>
            </form>
          </div>
        </Fade>
    </Modal>
  );
}
