import React from "react";
import PopupHome from "../components/landing/Popup-home";
import Banner from "../components/landing/banner_home";
import WhatIsIt from "../components/landing/what_is_it";
import Services from "../containers/landings/ServicesContainer";

import Contact from "../containers/landings/component/contactContainer";
import SubscribeContainer from "../containers/landings/component/SubscribeContainer";

export default () => (
  <>
    <PopupHome />
    <Banner />
    <WhatIsIt />
    <Services />
    <SubscribeContainer />
    <Contact />
  </>
);
