import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { Row, Col } from "shards-react";

class FooterLanding extends React.Component {
  constructor(props) {
    super(props);
    this.setStateContact = this.setStateContact.bind(this);
    this.state = {
      copyright: {
        to: "",
        label: "© 2019 Tu Aval - By Workode",
      },
      logo: "./images/logo_black.png",
      logo2: "./images/rig-logo.png",
      footerNav: [
        {
          to: "/about-us",
          label: "Nosotros",
        },
        {
          to: "/contact",
          label: "Contactanos",
        },
        {
          to: "/faq",
          label: "FAQ's",
        },
      ],
      social: [
        {
          to: "https://wa.me/",
          icon: "whatsapp",
        },
        {
          to: "https://wa.me/",
          icon: "whatsapp",
        },
        {
          to: "mailTo:erickorso@gmail.com",
          icon: "envelope",
        },
        {
          to: " https://www.google.com/maps/search/?api=1&query=",
          icon: "map-marker",
        },
        {
          to: "",
          icon: "facebook-f",
        },
        {
          to: "",
          icon: "instagram",
        },
        {
          to: "",
          icon: "linkedin-in",
        },
      ],
    };
  }
  componentWillMount() {
    let { contactUsActions, contact, isLoad } = this.props;

    if (!contact && !isLoad) {
      contactUsActions.getContact();
    } else if (contact) {
      this.setStateContact();
    }
  }

  componentDidUpdate(prevProps) {
    let { contact } = this.props;
    if (prevProps.contact != contact) {
      this.setStateContact();
    }
  }

  setStateContact() {
    let { contact } = this.props;
    this.setState({
      social: [
        {
          to: `https://wa.me/${contact.whatsapp}`,
          icon: "whatsapp",
          target: true,
        },
        {
          to: `tel:${contact.phone}`,
          icon: "phone",
          target: true,
        },
        {
          to: `mailto:${contact.email}`,
          icon: "envelope",
        },
        {
          to: `https://www.google.com/maps/search/?api=1&query=${contact.lat},${contact.lng}`,
          icon: "map-marker",
          target: true,
        },
        {
          to: `${contact.facebook}`,
          icon: "facebook-f",
          target: true,
        },
        {
          to: `${contact.instagram}`,
          icon: "instagram",
          target: true,
        },
        {
          to: `${contact.linkedin}`,
          icon: "linkedin-in",
          target: true,
        },
      ],
    });
  }

  render() {
    let { logo, logo2, social, footerNav, copyright } = this.state;

    return (
      <div className="footer">
        <div className="footer__row">
          <div className="footer__logo">
            <Row>
              <Col xs={6}>
                <Link to={"/"}>
                  <img src={logo2} alt="logo" />
                </Link>
              </Col>
              <Col xs={6}>
                <Link to={"/"}>
                  <img src={logo} alt="logo" />
                </Link>
              </Col>
            </Row>
          </div>

          <div className="footer__nav">
            <ul>
              {footerNav.map((link) => (
                <li>
                  <Link to={link.to}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="footer__row">
          <div className="footer__copy">
            <Link to={copyright.to}>{copyright.label}</Link>
          </div>
          <div className="footer__social">
            <ul>
              {social.map((link) => (
                <li>
                  <a href={link.to} target={link.target ? "_blank" : ""}>
                    <i class={`fa fa-${link.icon}`}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterLanding;
