import React, {useState} from "react";
import Button from "../../common/components/Button";

export default ({
  setOpenModal,
  result,
  goHome
}) => {

  const isMobile = window.screen.width < 768;
  const [resultTab, showResultTab] = useState(1);
  
  return (
    <div class="step step-saving">
      <div class="step__content">
        <div className="step__content__header">
          {result.total && (
            <h2>
              Accedé a una asistencia máxima de <strong>${result.total}</strong>
            </h2>
          )}
          <div className="step__content__header__wrapper">
            {result.offerFirst && (
              <div class="step__content__header__wrapper__column step__content__header__wrapper__column--left">
                <div className={`bg-white ${resultTab === 1 ? "active" : ""}`}>
                  <p>
                    Aval para financiaciones bancarias, on simple avalada,
                    descuento de cheques propios, pagaré bursátil, contratos
                    comerciales.
                  </p>
                  <h3>$ {result.offerFirst}</h3>
                </div>
              </div>
            )}
            {result.offerSecond && (
              <div class="step__content__header__wrapper__column step__content__header__wrapper__column--right">
                <div className={`bg-white ${resultTab === 2 ? "active" : ""}`}>
                  <p>
                    Aval para descuentos de cheques de terceros en mercado de
                    valores o entidades financieras.
                  </p>
                  <h3>$ {result.offerSecond}</h3>
                </div>
              </div>
            )}
          </div>
          <div className="dots-wrapper">
            <span className={`${resultTab === 1 ? "active" : ""}`} onClick={() => showResultTab(1)}></span>
            <span className={`${resultTab === 2 ? "active" : ""}`} onClick={() => showResultTab(2)}></span>
          </div>
          <div className="step__content__header__action">
            <Button
              label="QUIERO QUE ME LLAMEN"
              type="contain"
              onClick={() => setOpenModal(true)}
            />
            <Button
              label="VOLVER A HOME"
              type="rounded"
              onClick={goHome}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
