import React from "react";
import Button from "@material-ui/core/Button";
import ContactMap from "../../common/components/Map";
import useForm from "react-hook-form";
import Fade from "react-reveal/Fade";
import "./Contact.scss";
import Spinner from '../../common/spinner'
export default ({isLoad, actions, contact}) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const title1 = "Contactá";
  const title2 = "con nosotros";

  const onSubmit = data => { 
    actions.call({
      name: data.name,
      email: data.email,
      message:data.content
    })
    reset()
  };
  const marker= contact && contact.lng ? { lat: contact.lat, lng: contact.lng }: null;
  let social = [
 
    {
      to: `tel:${contact ? contact.phone : ""}`,
      icon: "phone",
      text:"Télefono",
      target:true,
      data: contact ? contact.phone : ""
    },
  
    {
      to: `https://www.google.com/maps/search/?api=1&query=${contact ? contact.lat: ""},${contact ? contact.lng: ""}`,
      icon: "map-marker",
      target:true,
      text: "Localización",
      data: contact ? contact.address: ""
    },
   
  ]


  return (
    <section className="contact">
    <div className="contact__wrapper">
      <div className="contact__form wow fadeIn">
        <h2>{title1}</h2>
        <h2>{title2}</h2>
        {
          social.map(s => (
            <p className="my-0">
              <a href={s.to} target={s.target ? "_blank" : ""}>
                <i class={`fa fa-${s.icon} mr-2`}></i> {s.data}
              </a>
            </p>
          ))
        }

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="line">
            <div className="form-wrapper">
              <label htmlFor="name">Nombre</label>
              <input
                className={errors.name && "input-error"}
                type="text"
                name="name"
                ref={register({
                  required: true,
                  maxLength: 80,
                  pattern: {
                    value: /[A-Z]{2,80}$/i
                  }
                })}
              />
            </div>
            <div className="form-wrapper">
              <label htmlFor="email">Email</label>
              <input
                className={errors.email && "input-error"}
                type="text"
                name="email"
                ref={register({
                  required: true,
                  maxLength: 80,
                  pattern: {
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  }
                })}
              />
            </div>
          </div>
          <div className="line">
            <div className="form-wrapper">
              <label htmlFor="content">Mensaje</label>
              <input
                className={errors.content && "input-error"}
                type="text"
                name="content"
                ref={register({
                  required: true,
                  maxLength: 200,
                  minLength: 5
                })}
              />
            </div>
          </div>
          <div className="line submit">
          <Button className="modal-btn btn-primary contain" type="submit">
            Enviar 
              {
                isLoad ?
                <Spinner type="success" spi="true" style={{width:"1rem", height:"1rem", "margin-left": "4px"}} />
                :""
              }
            </Button> 
          </div>
        </form>
      </div>
      <div className="contact__map">
        <ContactMap marker={marker} />
      </div>
    </div>
  </section>
  );
};
