import {get, post, patch, del, uploadFile} from './request'
import {getKey, setKey, removeKey} from '../utils/cache';

// const domain ='192.168.100.26'
const domain ='tuaval.com.ar'
const API_URL= `http://${domain}:3000/api`
// const API_URL= `http://${domain}:3001/api`

const Api = {

  forGot: (payload) => {
    return post(`${API_URL}/user/forgot`, {}, Object.assign({}, payload))
  },

  resetPassword: (payload) => {
    return post(`${API_URL}/user/resetPassword`, {}, Object.assign({}, payload))
  },

  register: (payload) => {
    return post(`${API_URL}/user/register`, {}, Object.assign({}, payload))
  },
  login: (payload) => {
    return post(`${API_URL}/user/login`, {}, payload)
  },
  logout: () => {
    return post(`${API_URL}/user/logout`)
  },

  getUser : () => {
    return get(`${API_URL}/user`)
  },
  getUsers : () => {
    return get(`${API_URL}/users`)
  },
  updateUser: (payload) => {
    return post(`${API_URL}/user`, {}, payload)
  },
  save : (action) => {
    setKey(action.name, action.payload)
  },
  remove: (action) => {
    removeKey(action.name)
  },
  getToken: () => {
    let storage = getKey("token")
    return storage
  },
  uploadAvatar: (payload) => {
    return uploadFile('post',`${API_URL}/user/upload`, payload)
  },
  getUsersFiles: () => {
    return get(`${API_URL}/users/files`)
  },
  uploadFileToS3: (payload) => {
    return uploadFile('post',`${API_URL}/user/upload-file`, payload)
  },
  uploadPopupImage: (payload) => {
    return uploadFile('post',`${API_URL}/user/upload-popup-image`, payload)
  },
  downloadFile: (query) => {
    return get(`${API_URL}/users/file`, query)
  },
  deleteUserFile: (query) => {
    return del(`${API_URL}/users/file`, query)
  },
  uploadItem: (payload) => {
    return uploadFile('post',`${API_URL}/item/${payload.type}/upload`, payload.payload)
  },
  createOrUpdateItem: (payload) => {
    return post(`${API_URL}/item`, {}, Object.assign({}, payload))
  },
  getTypes: () =>{
    return get(`${API_URL}/item/types`)
  },
  getItems: () =>{
    return get(`${API_URL}/items`)
  },
  deleteItem : (payload)=>{
    return post(`${API_URL}/item/delete`, {}, Object.assign({}, payload))
  },
  createOrUpdateContact : (payload) => {
    return post(`${API_URL}/contact-us`, {}, Object.assign({}, payload))
  },
  getContactUs:(payload) => {
    return get(`${API_URL}/contact-us`)
  },
  changePassword: (payload) => {
    // return uploadFile('post',`${API_URL}/user/changePassword`, payload, )
  },

  getValidateNosis:(payload) => {
    return post(`${API_URL}/nosis/validate`,{}, Object.assign({}, payload))
  },
  calculateNosis:(payload) => {
    return post(`${API_URL}/nosis/calculator`,{}, Object.assign({}, payload))
  },
  call:(payload) => {
    return post(`${API_URL}/contact`,{}, Object.assign({}, payload))
  },
  getCall:(payload) => {
    return get(`${API_URL}/contact`)
  },
  getCalculator:(payload) => {
    return get(`${API_URL}/nosis`)
  },

  getWeight:() => {
    return get(`${API_URL}/nosis/weight`)
  },
  setWeight:(payload) => {
    return post(`${API_URL}/nosis/weight`,{}, Object.assign({}, payload))
  },
}

export default Object.assign({}, Api)
