import React, {Component} from 'react'
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Row,
  Col,
  Container,
} from "shards-react";
import Slide from '../components/slider'
import {HowToBox, HowToForm} from '../components/how-to'

import PageTitle from "../components/common/PageTitle";
class HowToView extends Component{
    constructor(props){
        super(props)
        this.onEdit = this.onEdit.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onClear = this.onClear.bind(this)
        this.check = this.check.bind(this)
        this.Carousel=null
        this.state ={
            item:{
                title:"",
                description:""
            },
            error:{
                title:false,
                description:false
            }
        }
    }

    componentDidMount () {
        let {itemsActions} = this.props
        itemsActions.selectType('how_to')       
        itemsActions.addValue(this.state.item) 
    }

    componentDidUpdate(prevProps) {
        let {itemSelected, itemsActions, item} = this.props
        if (itemSelected && prevProps.itemSelected != itemSelected ){
            itemsActions.addValue(itemSelected)
        }

        if (!item.hasOwnProperty('title')){
            itemsActions.addValue(this.state.item) 
        }
    }
    componentWillUnmount(){
        this.props.itemsActions.clearType()
        this.props.itemsActions.clearItem()
    }

    onEdit (itemId) {
        let {itemsActions} = this.props
        itemsActions.selectItem(itemId)
    }
    onDelete (itemId) {
        let {itemsActions} = this.props
        itemsActions.deleteItem({itemId})
    }

    onChange (e) {
        let {itemsActions} = this.props
        itemsActions.addValue({[e.target.name]: e.target.value}) 
    }
    onClear (){
        this.props.itemsActions.clearItem()
    }
    check(){
        let {item} = this.props
        let error ={title:null, description:null}

        if (item.title === ""){
            error.title=true
        }else {
            error.title=false
        }
        if (item.description === ""){
            error.description=true
        }else {
            error.description=false
        }
        this.setState({error})
        if (error.title || error.description) return false
        return true
    }
    onSubmit () {
        
        
        if (this.check()){
            let {itemsActions,item, type} = this.props
            itemsActions.createOrUpdate({item :{...item, typeId: type.typeId}})
        }
    }

    render(){
        let {items, item} = this.props
        let props ={
            onDelete: this.onDelete,
            onEdit:this.onEdit,
            edit:true
        }
        let responsive ={
            0: { items: 1 },
            1024: { items: 1 },
        }
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="¿Qué hacemos?" subtitle="" className="text-sm-left" />
                </Row>
                <Row>
                    <Col xs={6}>
                        <div className="how-to " >
                            <p className="title-howTo px-15">¿Qué Hacemos?</p>
                            <Slide items={items} classContainer="flex-align-middle" responsive={responsive}
                                Component={HowToBox} propsComponent={props} config={{buttonsDisabled:true, ref:(el) => (this.Carousel = el)}}
                            >
                            </Slide>
                            <div className="px-15" >
                                <Button pill outline theme="light" className="b-primary mr-3" onClick={() => this.Carousel.slidePrev()}><span className="arrow">&lt;</span></Button>
                                <Button pill outline theme="light" className="b-primary ml-2" onClick={() => this.Carousel.slideNext()}><span className="arrow">&gt;</span></Button>
                            </div>
                        </div>
                      
                    </Col>
                    <Col xs={6}>
                        <HowToForm item={item} error={this.state.error} onClear={this.onClear} onChange={this.onChange} onSubmit={this.onSubmit} />
                    </Col>
                </Row>
            </Container>

           )
    }
}

export default HowToView