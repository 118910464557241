import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainSidebar from '../../components/layout/MainSidebar/MainSidebar.js'
import { sideNavBarSelectors, sideNavBarActions} from '../../state/redux/siderNavBar'
import {getUser} from "../../state/redux/session/selectors";

const {getMenuState, getSidebarItems} = sideNavBarSelectors


const mapStateToProps = state => ({
  menuState: getMenuState(state),
  siderbarItems: getSidebarItems(state),
  user: getUser(state),
})

const mapDispatchToProps = dispatch => ({
  sideNavBarActions: bindActionCreators(sideNavBarActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainSidebar)
