import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,
  Row,
  Form,
  Col,
  FormInput,
  FormTextarea,
  FormFeedback
} from "shards-react";
import ImageUpload from "../image-upload/image-uplaod";
const FormClient = ({
  item,
  onSubmit,
  onChange,
  onClear,
  error,
  onFilesAdded
}) => {
  const getImage = path => {
    if (path) {
      return path;
    }
    return require("../../images/file-manager/default.png");
  };
  return (
    <Card small className="blog-comments mb-2 m-auto">
      <CardBody className="">
        <Form>
          <Row form>
            {/* Pregnta frecuente */}
            <Col md="12" className="form-group">
              <ImageUpload
                src={getImage(item.image)}
                onFilesAdded={onFilesAdded}
                imgClass="edit-image width-20"
                dropClass=""
                loadAvatar={false}
              />
              <FormFeedback>Se requiere un Logo</FormFeedback>
            </Col>

            <Col md="12" className="form-group">
              <label htmlFor="title">Nombre</label>
              <FormInput
                id="title"
                name="title"
                placeholder="Nombre del cliente"
                value={item ? item.title : ""}
                onChange={onChange}
              />
            </Col>
            <Col md="12" className="form-group">
              <label htmlFor="link">Enlace</label>
              <FormInput
                id="link"
                name="link"
                placeholder="Enlace del cliente"
                value={item ? item.link : ""}
                onChange={onChange}
              />
            </Col>
          </Row>

          {/* Content :: Actions */}
          <div className="m-1 f-r blog-comments__actions">
            <ButtonGroup size="sm">
              <Button
                theme="white"
                onClick={() => {
                  onClear();
                }}
              >
                <span className="text-light">
                  <i className="material-icons">clear</i>
                </span>{" "}
                clear
              </Button>
              <Button
                theme="white"
                onClick={() => {
                  onSubmit();
                }}
              >
                <span className="text-light">
                  <i className="material-icons">edit</i>
                </span>{" "}
                {item.itemId ? "Actualizar" : "Crear"}
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormClient;
