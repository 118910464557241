import React, { useEffect, useState } from "react";
import ServiceBanner from "../components/Services/ServiceBanner";
import ServiceHowTo from "../components/Services/ServiceHowTo";
import ServiceWarranty from "../components/Services/ServiceWarranty";
import ServiceWarrantyTypes from "../components/Services/ServiceWarrantyTypes";

export default () => (
  <>
    <ServiceBanner />
    <ServiceHowTo />
    <ServiceWarranty />
    <ServiceWarrantyTypes />
  </>
);
