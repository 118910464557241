import React, {useEffect, useState} from "react";
import Fade from "react-reveal/Fade"; 
import "./AboutBanner.scss";

const bannerImg = "./images/about/map.png";

export default () => {

  return (
    <section className="banner-about">
      <Fade left>
        <div className="banner-about__text">
          <h2>
            NOSOTROS
          </h2>
          <h3>
            <span>
              Más de 20{" "}
            </span>
            <span>
              años apoyando{" "}
            </span>
          </h3>
          <h3>
            <span>
              a las empresas{" "}
            </span>
            <span>
              argentinas{" "}
            </span>
          </h3>
        </div>
      </Fade>
        <div className="banner-about__image">
          <img src={bannerImg} alt="about-img" />
        </div>
    </section>
  );
};
