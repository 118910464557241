import React, {Component} from 'react'
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Row,
  Col,
  Container,
} from "shards-react";
import SlideEdit from '../components/slider/slider-edit'
import {ClientForm, ClientComponent} from '../components/client'
import PageTitle from "../components/common/PageTitle";

import FormData from 'form-data';


class ClientView extends Component{
    constructor(props){
        super(props)
        this.onEdit = this.onEdit.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onClear = this.onClear.bind(this)
        this.check = this.check.bind(this)
        this.onFilesAdded= this.onFilesAdded.bind(this)
        this.state ={
            item:{
                title:"",
                link:"",
                image:""
            },
            error:{
                title:false,
                link:false,
                image:false
            }
        }
    }

    componentDidMount () {
        let {itemsActions} = this.props
        itemsActions.selectType('client')       
        itemsActions.addValue(this.state.item) 
    }

    componentDidUpdate(prevProps) {
        let {itemSelected, itemsActions, item} = this.props
        if (itemSelected && prevProps.itemSelected != itemSelected ){
            itemsActions.addValue(itemSelected)
        }

        if (!item.hasOwnProperty('title')){
            itemsActions.addValue(this.state.item) 
        }
    }
    componentWillUnmount(){
        this.props.itemsActions.clearType()
    }

    onEdit (itemId) {
        let {itemsActions} = this.props
        itemsActions.selectItem(itemId)
    }
    onDelete (itemId) {
        let {itemsActions} = this.props
        itemsActions.deleteItem({itemId})
    }

    onChange (e) {
        let {itemsActions} = this.props
        itemsActions.addValue({[e.target.name]: e.target.value}) 
    }
    onClear (){
        this.props.itemsActions.clearItem()
    }
    check(){
        let {item} = this.props
        let error ={title:null, description:null}

        if (item.title === ""){
            error.title=true
        }else {
            error.title=false
        }
        if (item.image === ""){
            error.image=true
        }else {
            error.image=false
        }
        if (item.link === ""){
            error.link=true
        }else {
            error.link=false
        }
        this.setState({error})
        if (error.title || error.image || error.link) return false
        return true
    }
    onSubmit () {    
        if (this.check()){
            let {itemsActions,item, type} = this.props
            itemsActions.createOrUpdate({item :{...item, typeId: type.typeId}})
        }
    }
    onFilesAdded(file){
        let {itemsActions} = this.props
        let formData = new FormData();
        formData.append("item", file, file.name);
        itemsActions.uploadImage(formData);
    }

    render(){
        let {items, item} = this.props
        let props={
            onDelete: this.onDelete,
            onEdit:this.onEdit,
            edit:true
        }
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Clientes" subtitle="" className="text-sm-left" />
                </Row>
                <Row className="mb-2">
                    <SlideEdit items={items} classContainer="bg-client flex-align-middle" 
                        Component={ClientComponent} propsComponent={props}
                    >
                    </SlideEdit>

                </Row>

                <Row>
                    <ClientForm item={item} onFilesAdded={this.onFilesAdded} onChange={this.onChange} onClear={this.onClear} onSubmit={this.onSubmit} error={this.state.error}> </ClientForm>
                </Row>
            </Container>

           )
    }
}

export default ClientView