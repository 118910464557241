import React from "react";
// import { BrowserRouter as Router, Route } from "react-router-dom";
// import { Router, Route } from "react-router";
import { Router, Route, Link } from 'react-router-dom';
import routes from "./routes";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./data/shards-dashboards.min.css";
import "./assets/style.css"
import {itemsSelectors, itemsActions} from './state/redux/items'

let {getTypes, getItems} = itemsSelectors
class RouterView extends React.Component {
  constructor (props){
    super(props)
  }
  componentDidMount (){
    let {itemsActions, types, items} = this.props
    if (types.length <= 0){
      itemsActions.getTypes()
    }
    console.log('items', items)
    if (items.length <= 0){
      itemsActions.getItems()
    }
  }
  render (){

    return (
      <Router history={this.props.history} >

        <div>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} {...route.props} />
                    </route.layout>
                  );
                }}
              />
            );
          })}
        </div>
      </Router>
    )
  }
}


const mapStateToProps = state => ({
  types: getTypes(state),
  items: getItems(state)
})

const mapDispatchToProps = dispatch => ({
  itemsActions: bindActionCreators(itemsActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouterView)


