import types from './types';
import { stat } from 'fs';

const initialState = {
  contact:[],
  load:false,
  edit:null,
  calls: []
}


const session = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTACTUS.SUCCESS:
      return {
        ...state,
        contact: action.payload,
        load: false
      }
    case types.CREATE_OR_UPDATE.SUCCESS:
    case types.CALL.SUCCESS:
      return {
            ...state,
            load: false
        }
    case types.GET_CALL.SUCCESS:
      return {
        ...state,
        calls: action.payload
      }    
    case types.ADD_VALUE:
          return {
            ...state,
            edit: {
              ...state.edit,
              ...action.payload}
          }
    case types.GET_CONTACTUS.REQUEST:    
    case types.CREATE_OR_UPDATE.REQUEST:
    case types.CALL.REQUEST:
    case types.GET_CALL.REQUEST:
        return {
            ...state,
            load: true
        }
    default:
    return state
  }
}


export default session
