import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserFilesView from '../../views/UserFilesView'
import { sessionSelectors} from '../../state/redux/session'
import { userActions} from '../../state/redux/user'

const { getUser } = sessionSelectors

const mapStateToProps = state => ({
    user: getUser(state),
    users: state.user.users,
    usersFiles: state.user.usersFiles,
    uploadingFile: state.user.uploadingFile,
})

const mapDispatchToProps = dispatch => ({
    userActions : bindActionCreators(userActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserFilesView)
