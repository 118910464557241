import axios from 'axios';
import {getKey} from '../utils/cache';

function request(method, url, params = {}, body = null, headers = {}, options = {}) {
  const reqParams = {
    method,
    responseType: 'json',
    headers: {
      Accept: 'application/json',
      ...headers,
    },
  };

  const token = getKey('token');
  if (!!token) {
    reqParams.headers.Authorization = `Bearer ${token}`;
  }

  const parsedParams = Object.keys(params).map(p => `${p}=${params[p]}`).join('&');

  if (method !== 'get' && method !== 'head' && body !== null) {
    if (!reqParams.headers['Content-Type']) {
      reqParams.headers['Content-Type'] = 'application/json';
    }
    reqParams.data = body;
  }

  reqParams.url = parsedParams ? `${url}?${parsedParams}` : url;

  return new Promise (async (resolve, reject) => {
    try {
      let result = await axios(reqParams)
      return resolve(result)
    } catch(e){
      return reject(e.response.data)
    }

  });
}

export function get(url, query, headers = {}, options = {}) {
  return request('get', url, query, {}, headers, options);
}

export function post(url, query, body = {}, headers = null, options = {}) {
  return request('post', url, query, body, headers, options);
}

export function patch(url, query, body = {}, headers = null, options = {}) {
  return request('patch', url, query, body, headers, options);
}

export function del(url, query, headers = null, options = {}) {
  return request('delete', url, query, {}, headers, options);
}

export function uploadFile(method, url, formData, config = {}) {
  const token = getKey('token');
  const headers = new Headers()
  headers.append('Authorization',`Bearer ${token}`)

  let keys = Object.keys(config)
  for (let key of keys ){
    headers.append(key, config[key])
  }

  return new Promise (async (resolve, reject) => {
    try {
      let query = await fetch(url, {
        method,
        body: formData,
        headers,
      });

      let result = await query.json()

      resolve(result)
    } catch(e){
      reject(e)
    }
  })
}
