import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginView from '../../views/admin/Loginview'
import { sessionActions, sessionSelectors} from '../../state/redux/session'

const {isLogin, isLoadSession, isError, messageError} = sessionSelectors


const mapStateToProps = state => ({
  isLogin: isLogin(state),
  isLoadSession: isLoadSession(state),
  isError: isError(state),
  messageError: messageError(state)
})

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginView)
