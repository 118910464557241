import React from "react";
import "./ServiceBanner.scss";
import Fade from "react-reveal/Fade";

const bannerImg = "./images/services/banner.png";

export default () => (
  <Fade left cascade>
    <section
      className="banner banner-services"
      style={{ backgroundImage: "url(" + bannerImg + ")" }}
      >
      <div className="banner-text">
        <h3>SERVICIOS</h3>
        <h2> <span>Tenemos</span> <span>el producto </span> </h2>
        <h2> <span>financiero</span> <span>perfecto para</span> </h2>
        <h2> <span>tu empresa.</span> </h2>
      </div>
    </section>
  </Fade>
);
