import React from "react";
import Fade from "react-reveal/Fade"; 
import { withRouter } from "react-router-dom";
import "./AboutWho.scss";
import AboutArticle from "../AboutArticle";


const AboutWho = ({ history }) => {
  const sendTo = to => {
    history.push(to);
    window.scrollTo(0, 0);
  };
  
  const aboutWho = "./images/about/nik-macmillan.jpg";
  const title = "¿QUIÉNES SOMOS?";
  const content =
  "RIG (Roberts - Ingrey Group), con cuatro generaciones de trayectoria en el mercado, afirma nuevamente a través de la constitución de RIG AVALES SGR - TU AVAL ™, su tradición familiar de compromiso pleno con el desarrollo de las Pequeñas y Medianas Empresas Argentinas.";
  const action = {
    label: "Calculá tu aval ahora →",
    onClick: () => sendTo("/calculator")
  };
  const years = {
    start: 1995,
    current: (new Date()).getUTCFullYear()
  };
  return (
    <section className="about-who">
      <Fade left cascade>
        <div className="column50 about-who__image">
          <div className="about-who__image__wrapper">
            <img src={aboutWho} alt={title} />
            <div className="about-who__image__wrapper__year about-who__image__wrapper__year--start">
              {years.start}
            </div>
            <div className="about-who__image__wrapper__year about-who__image__wrapper__year--current">
              {years.current}
            </div>
          </div>
        </div>
      </Fade>
      <div className="column50 about-who__article">
        <Fade right>
          <AboutArticle title={title} content={content} action={action} />
        </Fade>
      </div>
    </section>
  );
};

export default withRouter(AboutWho);
