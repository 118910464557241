import React from "react";
import Zoom from "react-reveal/Zoom";
import "./ServiceWarranty.scss";

const title = "¿QUÉ POSIBLES CONTRAGARANTÍAS PUEDO UTILIZAR?";
const warranties = [
  {
    label: "Hipotecas",
    icon: 'check'
  },
  {
    label: "Prendas",
    icon: 'check'
  },
  {
    label: "Warrants",
    icon: 'check'
  },
  {
    label: "Fideicomisos",
    icon: 'check'
  },
  {
    label: "Cesión de Derechos",
    icon: 'check'
  },
  {
    label: "Fianzas Personales",
    icon: 'check'
  }
];
export default () => (
  <Zoom>
    <section className="services-warranty">
      <h2>{title}</h2>
      <div className="services-warranty__list">
        {warranties.map((warrant, key) => (
          <div className="services-warranty__list__item" >
            <i class={`fa fa-${warrant.icon}`}></i>
            <h4>{warrant.label}</h4>
          </div>
        ))}
      </div>
    </section>
  </Zoom>
);
