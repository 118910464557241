import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import "./Modal.scss";

export default ({
  openModalSuccess,
  setOpenModalSuccess,
  goHomeP
}) => {
  const [open, setOpen] = React.useState(false);

  const goHome = () => {
    handleClose();
    goHomeP()
  };

  const handleOpen = () => {
    setOpen(true);
    setOpenModalSuccess(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModalSuccess(false);
  };

  useEffect(() => {
    if (openModalSuccess) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [openModalSuccess]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modal-tu-aval"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className="modal-tu-aval__content success">
          <div className="modal-tu-aval__content__wrapper">
            <h2 id="transition-modal-title">
              Muchas Gracias
            </h2>
            <h3>En breve nos pondremos en</h3>
            <h3>contacto con vos.</h3>
          </div>
          <div className="modal-btn-wrapper">
            <Button
              className="modal-btn"
              type="button"
              onClick={() => goHome()}
            >
              VOLVER A HOME
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
