import types from './types';

const initialState = {
  items:[],
  selected:null,
  item:{},
  types:[],
  selectedType:null
}


const session = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ITEMS.SUCCESS:
      return {
        ...state,
        items: action.payload,
        load: false
      }
    case types.GET_TYPES.SUCCESS:
        return {
          ...state,
          types: action.payload,
          load: false
        }  
    case types.SELECT_ITEM:
        return {
            ...state,
            selected: action.payload
        }
    case types.SELECT_TYPE:
      return {
        ...state,
        selectedType: action.payload
    }
    case types.CLEAR_ITEM:
        return {
            ...state,
            selected:null,
            item: {}
        }
    case types.CLEAR_TYPE:
      return {
        ...state,
        selectedType:null,
        // selected:null,
        // item: {}
      }
    case types.ADD_VALUE:
        return {
          ...state,
          item: {
            ...state.item,
            ...action.payload}
        }
    case types.CREATE_OR_UPDATE.SUCCESS:
    case types.DELETE_ITEM.SUCCESS:
        return {
            ...state,
            load: false
        }
    case types.GET_TYPES.REQUEST:    
    case types.CREATE_OR_UPDATE.REQUEST:
    case types.GET_ITEMS.REQUEST:
    case types.DELETE_ITEM.REQUEST:
    return {
        ...state,
        load: true
    }
    default:
    return state
  }
}


export default session
