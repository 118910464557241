import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";

class UserDetail extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user:{}
    }

  }


  componentDidMount(){
    let {user, userSelected, editProfile} = this.props
    if (!userSelected){
      this.setState({user})
    }
    if (userSelected){
      this.setState({user: userSelected})
    }
  }

  componentWillUnmount(){
    this.props.userActions.clearSelectUser()
  }

  render (){
    let {user, sessionActions} = this.props
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Detalle de Usuario"  md="12" className="ml-sm-auto mr-sm-auto" />

        </Row>
        <Row>
          <Col lg="8" align="center" className="center-block">
            <UserDetails userDetails={this.state.user} complete />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default UserDetail;
