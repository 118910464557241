import React, { Component } from 'react'
import Zoom from "react-reveal/Zoom";
import AliceCarousel from 'react-alice-carousel';
import { Desktop, Mobile } from "../common/components/Media";
import "react-alice-carousel/lib/alice-carousel.css";
import './slider.scss'

class Slider extends Component {
  constructor(props){
    super(props)
  
    this.state={
      default:{
        autoPlay:false, 
        playButtonEnabled:false,
        dotsDisabled:true,
        buttonsDisabled:true 
        
      },
      responsive : {
          0: { items: 1 },
          1024: { items: 5 },
      }
    }
  }
  componentDidMount () {
      let {items, responsive} = this.props
      let state={}
      if (items && items.length > 0) {
        state.items = items;
      }
      if (responsive){
        state.responsive= responsive
      }
      this.setState(state)
  }
 
  handleOnDragStart (e){
    e.preventDefault()
  } 

  render(){
    let {classContainer, items, config, Component:C, propsComponent, responsive} =this.props
    let configure = config ? {...this.state.default, ...config} : {...this.state.default}
    let responsivef = responsive ? {...this.state.responsive, ...responsive} : this.state.responsive
    return (
      <>
        <Desktop>
          <div className="valores-desktop">
            {items.map((block, key) => (
              <Zoom>
                <div
                  key={`valores-desktop-${key}`}
                  className="valores-desktop__item"
                  >
                  <div className="card__image">
                    {<img src={block.src} alt={block.title} />}
                  </div>
                  <div className="card__texts">
                    <h3>{block.title}</h3>
                    <p>{block.text}</p>
                  </div>
                </div>
              </Zoom>
            ))}
          </div>
        </Desktop>
        <Mobile>
          <div className={`container ${classContainer}`}>
            <AliceCarousel
              items={items}
              {...configure}
              mouseDragEnabled
              responsive={responsivef}
            >
              {items &&
                items.map((item, index) =>
                  C ? (
                    <C
                      item={item}
                      {...propsComponent}
                      index={index}
                      handleOnDragStart={this.handleOnDragStart}
                    />
                  ) : (
                    <a
                      key={index}
                      href={item.link}
                      target="_blank"
                      className="slider-link"
                    >
                      <img
                        className="slider-image"
                        src={item.image}
                        alt={item.title}
                        onDragStart={this.handleOnDragStart}
                      />
                    </a>
                  )
                )}
            </AliceCarousel>
          </div>
        </Mobile>
      </>
    );
  }
}

export default Slider