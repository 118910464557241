import React from 'react'
import {
    Card,
    CardBody,
    ButtonGroup,
    Button,
  } from "shards-react";
const PopupBox = ({item, image, itemId, onEdit, onDelete}) => (
    <Card small className="rubro-bg">
        <div
            className="rubro-img"
            style={{ backgroundImage: `url(${ image })` }}
        >
        </div>
        <CardBody className="flex-align-middle">
          <div>
            <h5 className="title-rubro">{item.title}</h5>
            <p className="descripcin-rubro">{item.description}</p>
          </div>
        </CardBody>
   
        {itemId ?
            <div className="blog-comments__actions">
                <ButtonGroup size="sm" className="f-r">
                    
                    <Button theme="white" onClick={() => {onDelete(itemId)}}>
                    <span className="text-danger">
                        <i className="material-icons">clear</i>
                    </span>{" "}
                    Eliminar
                    </Button>
                    <Button theme="white" onClick={() => {onEdit(itemId)}}>
                    <span className="text-light">
                        <i className="material-icons">more_vert</i>
                    </span>{" "}
                    Editar
                    </Button>
                </ButtonGroup>
            </div> 
            :""}
   
    </Card>



)

export default PopupBox