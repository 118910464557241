import types from './types'
const handleToggleSidebar= (payload) => ({
   type: types.TOGGLE_SIDEBAR
})

const setActiveMenu = (payload) => ({
  type: types.ACTIVE_MENU,
  payload
})

export default {
  handleToggleSidebar,
  setActiveMenu
}
