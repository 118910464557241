import {takeEvery, call, put, spawn, select} from 'redux-saga/effects';
import { push } from 'react-router-redux'
import Api from '../../services/api';
import { sessionTypes } from '../redux/session';


const { LOGIN, LOGOUT, RESTART, GET_USER , SAVE_TOKEN, REMOVE_TOKEN, FORGOT_PASSWORD, RESET_PASSWORD, CHANGE_PASSWORD} = sessionTypes


/***
 *   CHECK NOT AUTHORIZATION
 */

function* notAhutorizationIndexRequestedApiCall (action) {
  try {

    if (action.payload.status === 401) {
      yield put(push('/admin'))
      yield put({type: LOGOUT.REQUEST})
    }

  }catch (e) {

  }

}

function* notAhutorizationIndexRequestedSaga() {
  yield takeEvery(LOGIN.FAILURE, notAhutorizationIndexRequestedApiCall)
  yield takeEvery(GET_USER.FAILURE, notAhutorizationIndexRequestedApiCall)
  yield takeEvery(FORGOT_PASSWORD.FAILURE, notAhutorizationIndexRequestedApiCall)
  yield takeEvery(RESET_PASSWORD.FAILURE, notAhutorizationIndexRequestedApiCall)

}


/**
 * ********* FOR GOT PASSWORD **********
 */
function* forGotPasswordIndexRequestedApiCall (action) {
  try {

    const result = yield call(Api.forGot, action.payload)
    yield put({type: FORGOT_PASSWORD.SUCCESS, payload: result.data})

  }catch (e) {
    yield put({type: FORGOT_PASSWORD.FAILURE, payload: e.error || e})
  }

}

function* forGotPasswordIndexRequestedSaga() {
  yield takeEvery(FORGOT_PASSWORD.REQUEST, forGotPasswordIndexRequestedApiCall)
}

/**
 * ********* RESET PASSWORD **********
 */
function* resetPasswordIndexRequestedApiCall (action) {
  try {
    const result = yield call(Api.resetPassword, action.payload)
    yield put({type: RESET_PASSWORD.SUCCESS, payload: result.data})


  }catch (e) {
    yield put({type: RESET_PASSWORD.FAILURE, payload: e.error || e})
  }
}

function* resetPasswordIndexRequestedSaga() {
  yield takeEvery(RESET_PASSWORD.REQUEST, resetPasswordIndexRequestedApiCall)
}



/**
 * ********* LOGOUT **********
 */
function* sessionLogoutIndexRequestedApiCall (action) {
  try {
    // const result = yield call(Api.logout)
    // yield put({type: LOGOUT.SUCCESS, payload: result.data})

    yield put({type: REMOVE_TOKEN, payload:{name: "token"}})
    yield put(push('/admin'))
    yield put({type: LOGOUT.SUCCESS})
  }catch (e) {
    yield put({type: LOGOUT.FAILURE, payload: e.error || e})
  }
}

function* sessionLogoutIndexRequestedSaga() {
  yield takeEvery(LOGOUT.REQUEST, sessionLogoutIndexRequestedApiCall)
}

/**
 * ********** LOGIN *********
 */

function* sessionLoginIndexRequestedApiCall (action) {
  try {
    const result = yield call(Api.login, action.payload)
    yield put({type: LOGIN.SUCCESS, payload: result.data})
    yield put({type: SAVE_TOKEN, payload: {payload: result.data.token, name: 'token'}})

    if (result.data.role === 'ADMIN') {
      yield put(push('/dashboard'))
    } else {
      yield put(push('/user-file'))
    }


  }catch (e) {
    yield put({type: LOGIN.FAILURE, payload: e.error ? e.error : e})
  }
}

function* sessionLoginIndexRequestedSaga() {
  yield takeEvery(LOGIN.REQUEST, sessionLoginIndexRequestedApiCall)
}

/**
 * ************ GET USER ************
 */
function * sessionGetUserIndexRequestedApiCall() {
  try {
    const result = yield call(Api.getUser)
    yield put({type: GET_USER.SUCCESS, payload: result.data})
    if (result.data.role === 'ADMIN') {
      yield put(push('/dashboard'))
    } else {
      yield put(push('/user-file'))
    }
  }catch (e) {
    yield put({type: GET_USER.FAILURE, payload: e.error ? e.error : e})
  }
}

function * sessionGetUserIndexRequestedSaga() {
  yield takeEvery(GET_USER.REQUEST, sessionGetUserIndexRequestedApiCall)
}


/**
 * ****** SAVE TOKEN ********
 */
function* sessionSaveTokenIndexApiCall(action){
  try{
    yield call(Api.save, action.payload)
  }catch (e) {

  }
}

function* sessionSaveTokenIndexSaveSaga(){
  yield takeEvery(SAVE_TOKEN, sessionSaveTokenIndexApiCall)
}

function* sessionRemoveIndexApiCall(action){
  try{
    yield call(Api.remove, action.payload)
  }catch (e) {

  }
}

function* sessionRemoveTokenIndexSaga(){
  yield takeEvery(REMOVE_TOKEN, sessionRemoveIndexApiCall)
}


function* sessionIndexSaga () {
  yield spawn(sessionLoginIndexRequestedSaga)
  yield spawn(sessionLogoutIndexRequestedSaga)
  yield spawn(sessionSaveTokenIndexSaveSaga)
  yield spawn(sessionRemoveTokenIndexSaga)
  yield spawn(sessionGetUserIndexRequestedSaga)
  yield spawn(resetPasswordIndexRequestedSaga)
  yield spawn(forGotPasswordIndexRequestedSaga)
  yield spawn(notAhutorizationIndexRequestedSaga)
}


export default sessionIndexSaga
