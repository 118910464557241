import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WeightView from '../../views/WeightView'
import {  calculatorActions, calculatorSelectors} from '../../state/redux/calculator'

const { getWeight} = calculatorSelectors

const mapStateToProps = state => ({
  weight: getWeight(state)
})

const mapDispatchToProps = dispatch => ({
  calculatorActions: bindActionCreators(calculatorActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeightView)
