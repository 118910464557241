import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardView from '../../views/DashboardView'
import {  contactUsSelectors, contactUsActions} from '../../state/redux/contact_us'

import {  calculatorSelectors, calculatorActions} from '../../state/redux/calculator'

const { getCalls, isLoad} = contactUsSelectors
const {getCalculators, getLoading} = calculatorSelectors

const mapStateToProps = state => ({
  calls: getCalls(state),
  aLoadin: isLoad,
  cloading : getLoading(state),
  calculators: getCalculators(state)
})

const mapDispatchToProps = dispatch => ({
  contactUsActions: bindActionCreators(contactUsActions, dispatch),
  calculatorActions: bindActionCreators(calculatorActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardView)
