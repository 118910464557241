import types from './types'

const loginRequest = (payload) => ({
   type: types.LOGIN.REQUEST,
   payload
})

const logoutRequest = (payload) => ({
   type: types.LOGOUT.REQUEST,
   payload
})

const getUserRequest = () => ({
   type: types.GET_USER.REQUEST
})

const updateUserRequest = (payload) => ({
  type: types.UPDATE_USER.REQUEST,
  payload
})

const forGotPasswordRequest = (payload) => ({
  type: types.FORGOT_PASSWORD.REQUEST,
  payload
})


const resetPasswordRequest = (payload) => ({
  type: types.RESET_PASSWORD.REQUEST,
  payload
})


const updateUser = (payload)=>({
  type: types.CHANGE_USER,
  payload
})


export default {
  loginRequest,
  resetPasswordRequest,
  forGotPasswordRequest,
  updateUserRequest,
  getUserRequest,
  logoutRequest,
  loginRequest,
  updateUser
}
