import React, {Component} from 'react'
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  FormInput,
FormFeedback
} from "shards-react";

import Map from '../components/common/map';
import {Contatusform} from '../components/contact-us';
import PageTitle from "../components/common/PageTitle";
import LoadScripted from "../components/common/LoadScripted"



class ContactUsView extends Component{
    constructor(props){
        super(props)

        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.check = this.check.bind(this)
        this.state ={
            item:{
                vto : "",
                pneto : "",
                ebit : "",
                rneto : "",
                top : "",
            },
            error:{
                vto : false,
                pneto : false,
                ebit : false,
                rneto : false,
                top : false,
            },
            load: false
        }
    }

    componentDidMount () {
        let {calculatorActions, weight} = this.props
        if(!weight){
            calculatorActions.getWeight()        
        }else {
            this.setState({item: this.props.weight})
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.weight != this.props.weight){
            this.setState({item: this.props.weight})
        }
    }

    onChange (e) {
        this.setState({item: {...this.state.item, [e.target.name]: e.target.value}}) 
    }

    check(){
        let {item} = this.state
        let error ={
            vto: false,
            pneto: false,
            ebit: false,
            rneto: false,
            top: false,
        }

        if (item.vto === "" ){
            error.vto = true
        }
        if (item.pneto === "" ){
            error.pneto = true
        }
        if (item.ebit === "" ){
            error.ebit = true
        }
        if (item.rneto === "" ){
            error.rneto = true
        }
        if (item.top === "" ){
            error.top = true
        }
        this.setState({error})
        if (error.vto || error.pneto || error.ebit || error.rneto || error.top) return false
        return true
    }
    
    onSubmit () {
        console.log(this.check())
        let {calculatorActions} = this.props
        if (this.check()){
            calculatorActions.setWeight(this.state.item)
        }
    }

    render(){
        let {weight} = this.props
        let {item, error } = this.state
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Parametro del Calculador" subtitle="" className="text-sm-left" />
                </Row>
                <Card>
                 <Form>
                    <FormGroup>
                        <label htmlFor="vto">Tope días vta</label>
                        <FormInput
                        id="vto"
                        name="vto"
                        value={item ? item.vto : ""}
                        placeholder="Tope días vta"
                        onChange={this.onChange}
                        invalid={error.vto}
                        required

                        />
                        <FormFeedback>Se requiere un Tope días</FormFeedback>
                
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="pneto">Divisor Patrimonio Neto</label>
                        <FormInput
                        id="pneto"
                        name="pneto"
                        value={item ? item.pneto : ""}
                        placeholder="Divisor Patrimonio Neto"
                        onChange={this.onChange}
                        invalid={error.pneto}
                        required

                        />
                        <FormFeedback>Se requiere un Divisor Patrimonio Neto</FormFeedback>
                
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="ebit">Tope Ebit</label>
                        <FormInput
                        id="ebit"
                        name="ebit"
                        value={item ? item.ebit : ""}
                        placeholder="Tope Ebit"
                        onChange={this.onChange}
                        invalid={error.ebit}
                        required

                        />
                        <FormFeedback>Se requiere un Tope Ebit</FormFeedback>
                
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="rneto">Tope Resultado Neto </label>
                        <FormInput
                        id="rneto"
                        name="rneto"
                        value={item ? item.rneto : ""}
                        placeholder="Tope Resultado Neto "
                        onChange={this.onChange}
                        invalid={error.rneto}
                        required

                        />
                        <FormFeedback>Se requiere un Tope Resultado Neto </FormFeedback>
                
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="top">Tope Máximo de Crédito</label>
                        <FormInput
                        id="top"
                        name="top"
                        value={item ? item.top : ""}
                        placeholder="Tope Máximo de Crédito"
                        onChange={this.onChange}
                        invalid={error.top}
                        required

                        />
                        <FormFeedback>Se requiere un Tope Máximo de Crédito</FormFeedback>
                
                    </FormGroup>
                    <div className="m-1 f-r blog-comments__actions">
                  
                  <ButtonGroup size="sm">
                   
                      <Button theme="white" onClick={this.onSubmit}>
                      <span className="text-light">
                          <i className="material-icons">edit</i>
                      </span>{" "}
                      Guardar
                      </Button>
                  </ButtonGroup>
              </div>

                 </Form>
                    
                </Card>

                    
            </Container>

           )
    }
}

export default ContactUsView