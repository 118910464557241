import { createRequestTypes } from '../../../utils/parse'

const namespace = 'conatctUs'


const GET_CONTACTUS = createRequestTypes(`${namespace}/GET_CONTACTUS`)
const SELECT_CONTACTUS = `${namespace}/SELECT_CONTACTUS`
const CLEAR_CONTACTUS = `${namespace}/CLEAR_CONTACTUS`
const CREATE_OR_UPDATE = createRequestTypes(`${namespace}/CREATE_OR_UPDATE`)
const ADD_VALUE = `${namespace}/ADD_VALUE`
const CALL = createRequestTypes(`${namespace}/CALL`)
const GET_CALL = createRequestTypes(`${namespace}/GET_CALL`)
export default {
    GET_CONTACTUS,
    SELECT_CONTACTUS,
    CLEAR_CONTACTUS,
    CREATE_OR_UPDATE,
    ADD_VALUE,
    CALL,
    GET_CALL
}
