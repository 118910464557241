import React from "react";
import {Container, Row, Col, Button, Card, CardBody, ButtonGroup} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Chip,
  Tooltip,
  Typography
} from '@material-ui/core';
import Moment from "react-moment";
import moment from "moment"
import CircularIntegration from "../components/LoadingButton";

class UserFilesView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      openModal: false,
      userSelected: props.user.role === 'PARTNER' ? props.user.email : '0',
      openConfirm: false,
      renderProgress: props.uploadingFile,
      hasFile: false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleUserSelected = this.handleUserSelected.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
    this.handleDeleteFile = this.handleDeleteFile.bind(this)
    this.hideProgressBar = this.hideProgressBar.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
  }

  componentDidMount(){
    let { user, users, usersFiles, userActions } = this.props
    if (user.role === 'ADMIN' && users.length === 0){
      userActions.getUsersRequest()
      if (usersFiles.length === 0) {
        userActions.getUsersFiles()
      }
    }

    if (user.role === 'PARTNER') {
      this.setState({
        userSelected: user.email
      })
      if (usersFiles.length === 0) {
        userActions.getUsersFiles()
      }
    }
  }

  handleOpenModal() {
    this.setState({
      openModal: true,
    })
  }

  handleCloseModal() {
    this.setState({
      openModal: false,
      rowIdSelected: null,
      fileNameSelected: null,
      userSelected: this.props.user.role === 'PARTNER' ? this.props.user.email : '0',
      openConfirm: false,
      confirm: null,
      hasFile: false
    })
  }

  handleUserSelected(e) {
    this.setState({
      userSelected: e.target.value
    })
  }

  handleUpload() {
      const data = new FormData()
      data.append('file', document.querySelector('input[id="user-button-file"]').files[0])
      data.append('fileName',  document.querySelector('input[id="user-file-name"]').value)
      data.append('userEmail', this.state.userSelected)
      if (this.state.rowIdSelected) {
        data.append('id', this.state.rowIdSelected)
      }

      this.props.userActions.uploadFile(data)
  }

  handleDownload(id, key, bucket) {
    this.props.userActions.downloadFile({ id, key, bucket })
  }

  handleOpenConfirm(id, key, bucket) {
    this.setState({
      openConfirm: true,
      confirm: {
        id,
        key,
        bucket,
      }
    })

  }

  handleDeleteFile() {
    const { confirm: { id, key, bucket }} = this.state
    this.props.userActions.handleDeleteFile({ id, key, bucket })
    this.setState({
      openConfirm: false
    })
  }

  hideProgressBar() {
    this.setState({
      renderProgressBar: false
    })
  }

  handleFileChange(hasFile = true) {
    this.setState({ hasFile });
  }

  render (){

    return (
      <Container fluid className="user-files-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Archivos" className="text-sm-left" />
        </Row>
        <Row>
          <Col className="mb-4"><Button onClick={this.handleOpenModal} pill theme="success"><i className="material-icons">insert_drive_file</i> Agregar nuevo archivo</Button> </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Nombre
                    </th>
                    {
                      this.props.user.role === 'ADMIN' &&
                        <th scope="col" className="border-0">
                          Usuario
                        </th>
                    }
                    <th scope="col" className="border-0">
                      Creado
                    </th>
                    <th scope="col" className="border-0">
                      Modificado
                    </th>
                    <th scope="col" className="border-0">
                      Acciones
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.props.usersFiles.length > 0 &&
                    this.props.usersFiles
                      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                      .map(u =>
                        (
                          <tr key={u.id}>
                            <td>{u.name}</td>
                            {
                              this.props.user.role === 'ADMIN' &&
                              <td>{u.username}</td>
                            }
                            <td><Moment tz="America/Argentina/Rio_Gallegos">{new Date(u.created_at)}</Moment></td>
                            <td><Moment tz="America/Argentina/Rio_Gallegos">{new Date(u.updated_at)}</Moment></td>
                            <td>
                              <ButtonGroup size="sm" >
                                <Tooltip title="Descargar">
                                  <Button theme="info" onClick={() => this.handleDownload(u.id, u.key, u.bucket)}><i className="material-icons">get_app</i></Button>
                                </Tooltip>
                                { this.props.user.role === 'ADMIN' && (
                                  <Tooltip title="Eliminar">
                                    <Button theme="danger" onClick={() => this.handleOpenConfirm(u.id, u.key, u.bucket)}><i className="material-icons">delete</i></Button>
                                  </Tooltip>
                                )}
                              </ButtonGroup>
                              { !moment(u.updated_at).isBefore(Date.now(), 'day') && (
                                  <Chip color="primary" size="small" label="Nuevo" />
                              )}
                            </td>
                          </tr>
                        )
                    )
                  }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Subir un archivo</DialogTitle>
          <DialogContent dividers>
            <form encType="multipart/form-data" action="">
              <Box mb={2}>
                <FormControl fullWidth>
                  <InputLabel>Nombre</InputLabel>
                  <Input fullWidth name="fileName" id="user-file-name" />
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl>
                  <input
                    id="user-button-file"
                    multiple
                    type="file"
                    name="file"
                    accept="application/pdf,.doc,.docx,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,image/*,video/*"
                    onChange={this.handleFileChange}
                  />
                </FormControl>
              </Box>
              { this.props.user.role === 'ADMIN' && (
                <Box mb={2}>
                  <FormControl fullWidth>
                    <Select
                        value={this.state.userSelected}
                        onChange={this.handleUserSelected}
                        name="userEmail"
                    >
                      <MenuItem value="0" disabled>Seleccione un usuario</MenuItem>
                      {
                        this.props.users.length > 0 && this.props.users.map(u =>
                            <MenuItem key={u.email} value={u.email}>{u.email}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Box>
              )}
              <CircularIntegration
                  handleClick={this.handleUpload}
                  uploadingFile={this.props.uploadingFile}
                  callBack={this.handleCloseModal}
                  disabled={this.state.userSelect === '0' || !this.state.hasFile}
              />
            </form>
            { this.state.formError && (
                <Typography color="error">Campos requeridos invalidos</Typography>
            )}
          </DialogContent>
        </Dialog>
        { this.props.user.role === 'ADMIN' && (
          <Dialog
            open={this.state.openConfirm}
            onClose={this.handleCloseModal}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Estas seguro de eliminar este archivo?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseModal} theme="info">
                Cancelar
              </Button>
              <Button onClick={this.handleDeleteFile} theme="danger" autoFocus>
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    )
  }
}

export default UserFilesView;
