import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    ButtonGroup,
    Button

  } from "shards-react";
export default ({title, detail, itemId, onDelete, onEdit}) => {

    return (
        <Card small className="blog-comments mb-2">
            <CardHeader className="border-bottom">
                <h6 className="m-0">{title}</h6>
            </CardHeader>

        <CardBody className="">
            {
                detail.split('\n').map(i => 
                    <p  className="m-0  text-muted">{i}</p>
                )
            }
            {/* <p className="m-0  text-muted">{detail}</p> */}

                {/* Content :: Actions */}
                <div className="m-1 f-r blog-comments__actions">
                    <ButtonGroup size="sm">
                        
                        <Button theme="white" onClick={() => {onDelete(itemId)}}>
                        <span className="text-danger">
                            <i className="material-icons">clear</i>
                        </span>{" "}
                        Eliminar
                        </Button>
                        <Button theme="white" onClick={() => {onEdit(itemId)}}>
                        <span className="text-light">
                            <i className="material-icons">more_vert</i>
                        </span>{" "}
                        Editar
                        </Button>
                    </ButtonGroup>
                </div>
            </CardBody>

    </Card>
    )
}