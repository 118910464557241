import types from './types'

const changePasswordRequest = (payload) => ({
  type: types.CHANGE_PASSWORD.REQUEST,
  payload
})

const createOrUpdateUserRequest = (payload) => ({
  type: types.CREATE_OR_UPDATE_USER.REQUEST,
  payload
})

const getUsersRequest = (payload) => ({
  type: types.GET_USERS.REQUEST,
  payload
})
const deleteUsersRequest = (payload) => ({
  type: types.DELETE_USER.REQUEST,
  payload
})

const selectUser = (payload) => ({
  type: types.SELECT_USER,
  payload
})

const clearSelectUser = (payload) => ({
  type: types.CLEAR_SELECT_USER
})

const uploadAvatar = (payload) => ({
  type: types.UPLOAD_AVATAR.REQUEST,
  payload
})

const uploadFile = (payload) => ({
  type: types.UPLOAD_FILE.REQUEST,
  payload
})

const getUsersFiles = () => ({
  type: types.GET_USERS_FILES.REQUEST,
})

const downloadFile = (payload) => ({
  type: types.DOWNLOAD_FILE,
  payload
})

const handleDeleteFile = (payload) => ({
  type: types.DELETE_FILE,
  payload
})

const uploadPopupImage = (payload) => ({
  type: types.UPLOAD_POPUP_IMAGE.REQUEST,
  payload
})

export default {
  changePasswordRequest,
  getUsersRequest,
  selectUser,
  createOrUpdateUserRequest,
  deleteUsersRequest,
  clearSelectUser,
  uploadAvatar,
  getUsersFiles,
  uploadFile,
  downloadFile,
  handleDeleteFile,
  uploadPopupImage,
}
