import React from "react";
import MaterialTable from 'material-table';
import { Container, Row, Col } from "shards-react";
import {round} from '../utils/parse'
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        columns:[
            { title: 'Nombre', field: 'name' },
            { title: 'Mensaje', field: 'message' },
            { title: 'Email', field: 'email' },
            { title: 'Télefono', field: 'phone' },
            { title: 'Horas', field: 'hour' },
        ],
        calls: [],
        columnsCal: [
            { title: 'Nombre', field: 'name' },
            { title: 'CUIT', field: 'cuit' },
            { title: 'Email', field: 'email' },
            { title: 'Télefono', field: 'phone' },
            { title: 'Ofreta 1', field: 'offerFirst', render:rowData => round(parseFloat(rowData.offerFirst), -3) },
            { title: 'Oferta 2', field: 'offerSecond', render:rowData => round(parseFloat(rowData.offerSecond), -3) },
            { title: 'Rechazado', field: 'rejectedFields' }
        ],
        calculators: []  
    }
  }
  componentWillMount(){
    let {contactUsActions,
        calculatorActions} = this.props

        contactUsActions.getCalls()
        calculatorActions.getCalculators()
    }

    componentDidUpdate (preProps){
        let calls= []
        let calculators= []
        let  c= false
        if (preProps.calls != this.props.calls ){
            calls = this.props.calls
            c = true
        }
        if (preProps.calls != this.props.calls ){
            calculators = this.props.calculators       
            c= true
        }
        if (c) this.setState({calls, calculators})        
        
    }


  render() {
    let {  aLoadin, cloading,  } = this.props;
    let {calls, calculators} = this.state
    return (
      <Container>
        <Row>
          <Col style={{"margin": "1em 0"}}>
          <MaterialTable
                 options={{
                    search: false
                    }}
                title="Contactar"
                columns={this.state.columns}
                data={calls}
               
                />
          </Col>
        </Row>
        <Row>
          <Col>
          <MaterialTable
                 options={{
                    search: false
                    }}
                title="Calculados"
                columns={this.state.columnsCal}
                data={calculators}
               
                />


          </Col>
        </Row>
      </Container>
    );
  }
}

export default Dashboard;
