import React from "react";
import Faq from "../components/Faq";

class FaqView extends React.Component{
  constructor(props){
    super(props)
  }

  componentDidMount () {
    let {itemsActions} = this.props
    itemsActions.selectType('faq')       
  }


  render(){


    return (
      <Faq questions={this.props.items.reverse()} />
    )
  }
}

export default FaqView




// export default ({question}) => (
//   <>
    
//   </>
// );
