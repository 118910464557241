import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "../../common/components/Button";
import CalculatorNav from "./CalculatorNav";
const useStyles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
    // width: 265
  },
  dense: {
    marginTop: 25
  },
  menu: {
    width: 265
  }
});
class CalculatorStep8 extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      valid: {
        email: true,
        calculate:true
      }
    }
  }

  validStepOne (value) {
    let {calculatorActions} = this.props
    calculatorActions.setValues({email:value})
    if (
      !value ||
      value === "" ||
      !value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      
    } else {
      
    }
  }


  calculator (){
    let {calculatorActions, payload} = this.props
    calculatorActions.calculate(payload)
  }

  componentDidUpdate ( preProv){
    let {calculate,setStep, nextStep, step} = this.props
    if (preProv.calculate != calculate){
      if (calculate.status){
        setStep(nextStep(step));
      }else{
        this.setState({valid:{calculate:false}})
      }
    }    
  }



  render(){

    let  {
      setStep,
      prevStep,
      nextStep,
      step,
      formInfo,
      formStepStyle,
      loading,     
      classes
    }= this.props
    let {valid} = this.state

    return (
      <div class={`step ${formStepStyle}`}>
        <div className="step__nav">
          <CalculatorNav
            setStep={setStep}
            nextStep={nextStep}
            prevStep={prevStep}
            step={step}
            
            last={true}
          />
        </div>
        <div className="step__content">
          <div className="step__content__header">
            <TextField
              id="standard-helperText"
              label="Ingresá tu e-mail para que podamos enviarte tu oferta"
              defaultValue={formInfo.email}
              className={classes.textField}
              helperText={
                !valid.email && "Debes ingresar solo un email valido"
                && !valid.calculate && "Los valores ingresesados no cumplen con los requisitos minimos"
              }
              margin="normal"
              error={!valid.email || !valid.calculate }
              onChange={e => this.validStepOne(e.target.value)}
              onKeyPress={e => {
                if (e.key === "Enter" )  {
                  this.calculator()
                }
              }}
            />
          </div>
          <div className="step__content__footer">
            <div className="step__content__footer__wrapper">
              <Button
                label="CALCULAR"
                type="contain"
                onClick={() => this.calculator()}
                icon="check"
                iconLast={true}
                disabled={!valid.email}
                loading={loading}
              />
              <p>presióna ENTER</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withStyles(useStyles)(CalculatorStep8)

// () => {
//   const [validStep, setValidStep] = useState(true);
//   const [disabled, setDisabled] = useState(true);


//   const classes = useStyles();



//   useEffect(() => {
//     if (formInfo.email !== "" && validStep) {
//       setDisabled(false);
//     }
//   });

 
// };

