import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";

class UserProfileLite extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user:{}
    }
    this.onChange = this.onChange.bind(this)
    this.onFilesAdded = this.onFilesAdded.bind(this)
  }


  componentDidMount(){
    let {user, userSelected, newUser } = this.props
    if (!newUser){
      if (!userSelected){
        this.setState({user})
      }
      if (userSelected){
        this.setState({user: userSelected})
      }
    }
  }

  onFilesAdded(file){
    let {userActions} = this.props
    let formData = new FormData();
    formData.append("avatar", file, file.name);
    userActions.uploadAvatar(formData);
  }


  componentDidUpdate(prevProps){
    if (this.props.uploadImage && prevProps.uploadImage != this.props.uploadImage ){
      this.onChange({image: this.props.uploadImage})
    }
  }

  onChange (payload) {
    this.setState({
      user: {
        ...this.state.user,
        ...payload
      }
    })
  }

  componentWillUnmount(){
    this.props.userActions.clearSelectUser()
  }

  render (){
    let {sessionActions, loadAvatar, uploadImage} = this.props
    return (
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title="User Profile"  md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col lg="4">
              <UserDetails userDetails={this.state.user} loadAvatar={loadAvatar}  onFilesAdded={this.onFilesAdded}/>
            </Col>
            <Col lg="8">
              <UserAccountDetails newUser={this.state.user ? true : false} userDetails={this.state.user} actions={sessionActions} onChange={this.onChange}/>
            </Col>
          </Row>
        </Container>
    )
  }
}

export default UserProfileLite;
