import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from "react-router-dom";
import HeaderView from '../../components/layout/Landing/Header'
import { sideNavBarSelectors, sideNavBarActions} from '../../state/redux/siderNavBar'

const {getNavBarItems, getActiveItem} = sideNavBarSelectors


const mapStateToProps = state => ({
  active:getActiveItem(state),
  navBarItems: getNavBarItems(state)
})

const mapDispatchToProps = dispatch => ({
    sideNavBarActions: bindActionCreators(sideNavBarActions, dispatch)
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderView);
