import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";


class MainSidebar extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    let {sideNavBarActions, menuState, siderbarItems, user} = this.props

    const classes = classNames(
        "main-sidebar",
        "px-0",
        "col-12",
        menuState && "open"
    );

    return (
        <Col
            tag="aside"
            className={classes}
            lg={{ size: 2 }}
            md={{ size: 3 }}
        >
          <SidebarMainNavbar hideLogoText={this.props.hideLogoText}  handleToggleSidebar={sideNavBarActions.handleToggleSidebar}/>

          <SidebarNavItems items={siderbarItems} user={user}/>
        </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
