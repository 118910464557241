import React, {Component} from 'react'
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import AppView from '../App'

const mapStateToProps = state => ({


})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppView)
