import {takeEvery, call, put, spawn, select} from 'redux-saga/effects';

import { push } from 'react-router-redux'
import Api from '../../services/api';
import {itemsTypes, itemsSelectors} from '../redux/items'

const {GET_ITEMS, UPLOAD_ITEM, CREATE_OR_UPDATE, DELETE_ITEM, GET_TYPES, ADD_VALUE} = itemsTypes

/*
    DELETE ITEM
*/
function* deleteItemIndexApiCall(action){
    try{
      let result = yield call(Api.deleteItem, action.payload)
      yield put({type:DELETE_ITEM.SUCCESS, payload: result.data})
      yield put({type: GET_ITEMS.REQUEST})
    }catch (e) {
  
    }
}
  
function* deleteItemIndexSaga(){
    yield takeEvery(DELETE_ITEM.REQUEST, deleteItemIndexApiCall)  
}

/*
    UPLOAD IMAGE ITEM
*/
function* updateImageItemIndexApiCall(action){
    try{
      const type = yield select(itemsSelectors.getTypeSelected);
      let result = yield call(Api.uploadItem, {type, payload: action.payload})
      yield put({type:UPLOAD_ITEM.SUCCESS, payload: result})
      let path =result.path
      let res= path.substring(path.indexOf('build')+5, path.length)
      yield put({type: ADD_VALUE, payload: {image: res}})
    }catch (e) {
      console.error(e)
      yield put({type:UPLOAD_ITEM.FAILURE, payload: e})
    }
}
  
function* updateImageItemIndexSaga(){
    yield takeEvery(UPLOAD_ITEM.REQUEST, updateImageItemIndexApiCall)  
}


/*
  CREATE OR UPDATE ITEM
*/
function* createOrUpdateItemIndexApiCall(action){
    try{
      let result = yield call(Api.createOrUpdateItem, action.payload)
      yield put({type:CREATE_OR_UPDATE.SUCCESS, payload: result.data})
      yield put({type: GET_ITEMS.REQUEST})
    }catch (e) {
  
    }
}
  
function* createOrUpdateItemIndexSaga(){
    yield takeEvery(CREATE_OR_UPDATE.REQUEST, createOrUpdateItemIndexApiCall)  
}



/*
  GET TYPES
*/
function* getTypesIndexApiCall(action){
    try{
      let result = yield call(Api.getTypes)
      yield put({type:GET_TYPES.SUCCESS, payload: result.data})  
    }catch (e) {
      console.error(e)
    }
  }
  
function* getTypesIndexSaga(){
  yield takeEvery(GET_TYPES.REQUEST, getTypesIndexApiCall)  
}


/*
  GET ITEMS
*/
function* getItemsIndexApiCall(action){
    try{
      let result = yield call(Api.getItems)
      yield put({type:GET_ITEMS.SUCCESS, payload: result.data})  
    }catch (e) {
  
    }
  }
  
function* getItemsIndexSaga(){
    yield takeEvery(GET_ITEMS.REQUEST, getItemsIndexApiCall)  
}
  

function* userIndexSaga () {
    yield spawn(getItemsIndexSaga)
    yield spawn(getTypesIndexSaga)
    yield spawn(createOrUpdateItemIndexSaga)
    yield spawn(deleteItemIndexSaga)
    yield spawn(updateImageItemIndexSaga)
}
  
  export default userIndexSaga
  