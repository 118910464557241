import React, {Component} from 'react'
import PropTypes from "prop-types";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Row,
  Col,
  Container,
  Badge
} from "shards-react";
import {CategoryBox} from "../components/category"
import PageTitle from "../components/common/PageTitle";
class CategoryView extends Component{
    constructor (props){
        super(props)
        this.onAddCategory = this.onAddCategory.bind(this)
        this.onEdit= this.onEdit.bind(this)
        this.onDelete= this.onDelete.bind(this)
        this.onAddPopup = this.onAddPopup.bind(this)
    }

    componentDidMount () {
        let {itemsActions} = this.props
        itemsActions.selectType('category')       
 
    }
    componentWillUnmount(){
        this.props.itemsActions.clearType()
    }
   

    onAddCategory(){
        let {history,itemsActions} = this.props
        itemsActions.clearItem()
        history.push('/category/edit')
    }

    onAddPopup(){
        let {history,itemsActions,popup} = this.props
        itemsActions.clearItem()
        itemsActions.selectItem(popup)
        history.push('/popup/edit')
    }

    onEdit (itemId){
        let {itemsActions, history} = this.props
        itemsActions.selectItem(itemId)
        history.push('/category/edit')
    }
    onDelete (itemId) {
        let {itemsActions} = this.props
        itemsActions.deleteItem({itemId})
    }
    

    getImage (path) {
        if (path ){
            return path  
        }
        return require('../images/file-manager/default.png')
    }

    render (){
        let {items} = this.props
        
        return (  <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Rubros" subtitle="Manego de Rubros" className="text-sm-left" />
        </Row>
        <Row >
          <Col className="mb-4">
            <Button onClick={this.onAddCategory} pill theme="success"><i class="material-icons">add_box</i> Agregar Rubro</Button> 
            <Button className="ml-3" onClick={this.onAddPopup} pill theme="secondary"><i class="material-icons">add_box</i> Agregar Popup</Button> 
        </Col>
        </Row>
        <Row>
            
            {items.map((item, idx) => (
                <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
                    <CategoryBox itemId={item.itemId} item={item} onDelete={this.onDelete} onEdit={this.onEdit} image={this.getImage(item.image)} />    
                </Col>
            ))}
        
          
        </Row>
    </Container>
)
    }
}

export default CategoryView