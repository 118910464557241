import React, {Component} from 'react'
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Row,
  Col,
  Container,
  Badge
} from "shards-react";
import {PopupBox, PopupForm} from "../components/popup"
import PageTitle from "../components/common/PageTitle";

class PopupEditView extends Component{
    constructor (props){
        super(props)
        this.onClear= this.onClear.bind(this)
        this.onChange= this.onChange.bind(this)
        this.onSubmit= this.onSubmit.bind(this)
        this.onFilesAdded= this.onFilesAdded.bind(this)
        this.state={
            item:{
                title:"",
             
                image:""
            },
            error:{
                title:false,
                image:false
            }
        }
    }

    componentDidMount () {
        let {itemsActions, item, popup} = this.props
        itemsActions.selectType('popup')
        if (popup){
            itemsActions.addValue(popup)
        }
        if (!popup.hasOwnProperty('itemId')){
            itemsActions.addValue(this.state.item)
        } 
    }
    componentWillUnmount(){
        this.props.itemsActions.clearType()
    }

    onChange (e) {
        let {itemsActions} = this.props
        itemsActions.addValue({[e.target.name]: e.target.value})
    }

    onClear (){
        this.props.itemsActions.clearItem()
    }

    check(){
    
        let {item} = this.props
        let error ={title:null, image:null}
        if (item.title === ""){
            error.title=true
        }else {
            error.title=false
        }
       
        if (item.image === ""){
            error.image=true
        }else {
            error.image=false
        }
        this.setState({error})
        if (error.title || error.image) return false
        return true
    }

    onSubmit (){
       
        let {history} = this.props
        if (this.check()){
            let {itemsActions,item, type} = this.props
            itemsActions.createOrUpdate({item :{...item, typeId: type.typeId}})
            history.push('/categories')
        }
    }
    onFilesAdded(file){
        let {itemsActions} = this.props
        let formData = new FormData();
        formData.append("item", file, file.name);
        itemsActions.uploadImage(formData);
    }

    getImage (path) {
        if (path){
           
            return path
        }
        return require('../images/file-manager/default.png')
    }
    render (){
        let {item} = this.props
        return (  <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Agregar Popup" subtitle="" className="text-sm-left" />
        </Row>
      
        <Row>
            <Col md={8}>
                <PopupForm item={item} error={this.state.error} onChange={this.onChange} onClear={this.onClear} onSubmit={this.onSubmit} onFilesAdded={this.onFilesAdded} image={this.getImage(item.image)} />
            </Col>
            <Col md={4}>

                <PopupBox item={item} title={item.title || "Titulo de Ejemplo"} description={item.description || "Descripticón de ejemplo."} image={this.getImage(item.image)} />
            </Col>
        </Row>
    </Container>
)
    }
}

export default PopupEditView