import React from "react";
import PropTypes from "prop-types";
import Moment from 'react-moment';
import 'moment-timezone';
import ImageUpload from '../image-upload/image-uplaod'
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from "shards-react";

const getImageUser = (path) => {
  if (path ){
    if (path.indexOf('http') != -1 || path.indexOf('build') === -1)
      return path
    else{
      let p = path.substring(path.indexOf('build')+5, path.length)
      return p
    }
  }
  return require('../../images/avatars/icon-user.png')
}

const UserDetails = ({ userDetails, complete, onFilesAdded, loadAvatar }) => (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        {!complete ? 
          <ImageUpload onFilesAdded={onFilesAdded} loadAvatar={loadAvatar} imgClass="rounded-circle width-110" dropClass="width-128 mx-auto" src={getImageUser(userDetails.image)} />
        :
          <img
          className="rounded-circle "
          src={getImageUser(userDetails.image)}
          alt={`${userDetails.name || ''} ${userDetails.lastname || ''}`}
          width="110"
        />
        }

        
      </div>
      <h4 className="mb-0">{`${userDetails.name || ''} ${userDetails.lastname || ''}`}</h4>

      {
      //   <Button pill outline size="sm" className="mb-2">
      //   <i className="material-icons mr-1">person_add</i> Follow
      // </Button>
    }
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem small className="">
        <strong className="text-muted d-block mb-2">
          DNI
        </strong>
        <span>{userDetails.dni}</span>
      </ListGroupItem>
      <ListGroupItem small>
        <strong className="text-muted d-block mb-2">
          Fecha Nacimiento
        </strong>
        <span>{userDetails.birthDay ? <Moment format="DD/MM/YYYY" tz="America/Argentina/Rio_Gallegos">{new Date(userDetails.birthDay)}</Moment>: ''}</span>
      </ListGroupItem>

      <ListGroupItem small>
        <strong className="text-muted d-block mb-2">
          email
        </strong>
        <span>{userDetails.email || ''}</span>
      </ListGroupItem>
      <ListGroupItem small>
        <strong className="text-muted d-block mb-2">
          Télefono
        </strong>
        <span>{userDetails.phone || ''}</span>
      </ListGroupItem>
      <ListGroupItem small>
        <strong className="text-muted d-block mb-2">
          Address
        </strong>
        <span>{userDetails.address || ''}</span>
      </ListGroupItem>
      {
        complete ? 
        <ListGroupItem small>
        <strong className="text-muted d-block mb-2">
          Datos adicionales
        </strong>
        <span>{userDetails.extra || ''}</span>
      </ListGroupItem>
        :''
      }


    </ListGroup>
  </Card>
);

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};


export default UserDetails;
