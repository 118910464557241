import React from "react";

export default ({ valid, setStep, prevStep, nextStep, step, last }) => (
  <div className="form-nav">
    {step > 1 && (
      <div
        className="form-nav__icon form-nav__icon--prev"
        onClick={() => setStep(prevStep(step))}
      >
        <i className="fas fa-chevron-up"></i>
      </div>
    )}
    {!last && valid && (
      <div
        className="form-nav__icon form-nav__icon--next"
        onClick={() => setStep(nextStep(step))}
      >
        <i className="fas fa-chevron-down"></i>
      </div>
    )}
  </div>
);
