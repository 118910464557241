import React from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import "./AboutHistory.scss";
import AboutArticle from "../AboutArticle";


const AboutHistory =  ({history}) => {
  const sendTo = to => {
    history.push(to);
    window.scrollTo(0, 0);
  };

  const AboutHistoryImg = "./images/about/architectural.jpg";
  const title = "NUESTRA HISTORIA";
  const content = "Nuestro equipo de trabajo cuenta con una historia de más de 20 años en el análisis y evaluaciones de riesgo crediticio a pequeñas y medianas empresas, habiendo desempeñado en entidades bancarias, de Factoring y Compañía financiera de primera línea.";
  const action = {
    label: "Calculá tu aval ahora →",
    onClick: () => sendTo("/calculator")
  };
  return (
    <section className="about-history">
      <div className="column50 about-history__article">
        <Fade top cascade>
          <AboutArticle title={title} content={content} action={action} />
        </Fade>
      </div>
      <div className="column50 about-history__image">
        <div className="about-history__image__wrapper">
          <Fade top cascade>
            <img src={AboutHistoryImg} alt={title} />
            <div className="float-div">
              <div className="icon-wrapper">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div className="text-wrapper">
                <p> Nuestras oficinas en </p>
                <p> Buenos Aires </p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}

export default withRouter(AboutHistory);
