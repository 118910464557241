import React, {Component} from 'react'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {isEqual} from '../../utils/parse'
class SliderEdit extends Component {
  constructor(props){
    super(props)
  
    this.state={
      default:{
        autoPlay:false, 
        playButtonEnabled:false,
        dotsDisabled:true,
        buttonsDisabled:true 
        
      },
      responsive : {
          0: { items: 1 },
          1024: { items: 5 },
      }
    }
  }
  componentDidMount () {
      let {items, responsive} = this.props
      let state={}
      if (items.length > 0){
        state.items =items
      }
      if (responsive){
        state.responsive= responsive
      }
      this.setState(state)
  }
 
  

  handleOnDragStart (e){
    e.preventDefault()
  } 

  render(){
    let {classContainer, children, items, config, Component:C, propsComponent, responsive} =this.props
    let configure = config ? {...this.state.default, ...config} : {...this.state.default}
    let responsivef = responsive ? {...this.state.responsive, ...responsive} : this.state.responsive
 
    
    return (
    <React.Fragment>
        <div className={`container ${classContainer}`} >
            <AliceCarousel items={items} {...configure} mouseDragEnabled responsive={responsivef}  >
                {
                  items.map((item,index) => (
                    C ?
                      <C item={item} {...propsComponent} index={index} handleOnDragStart={this.handleOnDragStart} />
                    :   
                      <a key={index} href={item.link} target="_blank" className="slider-link">
                          <img className="slider-image" src={item.image} alt={item.title} onDragStart={this.handleOnDragStart}/> 
                      </a>
                  ))
                }
            </AliceCarousel>
             
        </div>
    </React.Fragment>

    )
  }
}

export default SliderEdit