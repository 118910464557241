import React, { useState } from "react";

export default ({ active, index, setHow, itemsCount, item }) => {
  const [white, setWhite] = useState(null);
  return (
    <div
      class="circle-item"
      style={{
        transform: "rotate(-" + ((360 * index) / itemsCount + 90) + "deg)"
      }}
    >
      <div
        class={`circle-item-img ${active && "active"}`}
        onClick={() => setHow(index)}
        onMouseOver={() => setWhite(true)}
        onMouseLeave={() => setWhite(false)}
      >
        <img
          src={
            active || white
              ? `./images/about/${item.icon}-white.svg`
              : `./images/about/${item.icon}.svg`
          }
          alt={item.title}
          style={{
            transform: "rotate(" + ((360 * index) / itemsCount + 90) + "deg)"
          }}
        />
      </div>
    </div>
  );
};
