import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "../../common/components/Button";
import CalculatorNav from "./CalculatorNav";
import {validateCuit} from "../../../utils/parse"
import Spinner from "../../common/spinner"


const useStyles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: 25
  },
  menu: {
    width: 265
  }
});

class CalculatorStep1 extends React.Component{
  constructor(props){
    super(props)
    
    this.state={
      form: {
        cuit: true,
        cuitNosis: true
      },
      
    }
   
  }
  validateNosis () {
    let {calculatorActions,payload} = this.props
    calculatorActions.validateCuit(payload)
  }

  useEffect() {
    // if (formInfo.cuit !== "" && validStep) {
    //   setDisabled(false);
    // }
  }
  componentDidUpdate(prevProps){
    let {setStep, nextStep, step, validate} = this.props
    if (validate != prevProps.validate){
      if ((typeof validate === 'object' && validate.status === true) || validate === true){
        setStep(nextStep(step))
      }else{
        this.setState({form:{cuitNosis:false}})
      }
    }
  }


  validStepOne (value){
    let {setFormInfo, setValidStep, setDisabled, formInfo, calculatorActions} = this.props
    // setFormInfo({ ...formInfo, cuit: value });
    calculatorActions.setValues({cuit:value})
    // setValidStep(false);
    let cuit= false
    if (!value || value === "" || !validateCuit(value)) {
      cuit= false       
    } else {
      cuit= true;
    }
    this.setState({form:{cuit}})
  }
  render(){
    let { setStep,
      prevStep,
      nextStep,
      step,
      classes,
      formInfo, 
      formStepStyle,
      loading} = this.props
    // const [validStep, setValidStep] = useState(true);
    // const [disabled, setDisabled] = useState(true);
    let {form} = this.state
    return (
      <div class={`step ${formStepStyle}`}>
        <div className="step__nav">
          <CalculatorNav
            setStep={setStep}
            step={step}
            // valid={!disabled}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        </div>
        <div className="step__content">
          <div className="step__content__header">
            <TextField
              id="standard-helperText"
              label="Ingresá tu CUIT"
              defaultValue={formInfo.cuit}
              className={classes.textField}
              helperText={
                (!form.cuit && "El CUIT ingresado es incorrecto") 
                && (!form.cuitNosis && "El CUIT ingresado no cumple los requisitos")
              }
              margin="normal"
              error={!form.cuit || !form.cuitNosis }
              onChange={e => this.validStepOne(e.target.value)}
              onKeyPress={e => {
                if (e.key === "Enter" ) {
                  setStep(nextStep(step))
                }
              }}
            />
          </div>
          <div className="step__content__footer">
            <div className="step__content__footer__wrapper">
              <Button
                label="OK"
                type="contain"
                onClick={() => setStep(nextStep(step))}
                icon="check"
                iconLast={true}
                disabled={!form.cuit}
                loading={loading}
              />
              <p>presióna ENTER</p>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}





export default withStyles(useStyles)(CalculatorStep1)