import {takeEvery, call, put, spawn, select} from 'redux-saga/effects';
import { push } from 'react-router-redux'
import Api from '../../services/api';
import { sessionTypes } from '../redux/session';
import {userTypes} from '../redux/user'

const {  UPDATE_USER } = sessionTypes

const { GET_USERS, CREATE_OR_UPDATE_USER, CHANGE_PASSWORD, DELETE_USER, UPLOAD_AVATAR, LOAD_AVATAR, GET_USERS_FILES, UPLOAD_FILE, DOWNLOAD_FILE, DELETE_FILE, UPLOAD_POPUP_IMAGE} = userTypes

/*
  GET USERS
*/
function* getUsersIndexApiCall(action){
  try{
    let result = yield call(Api.getUsers)
    yield put({type: GET_USERS.SUCCESS, payload: result.data})
  }catch (e) {

  }
}

function* getUsersIndexSaga(){
  yield takeEvery(GET_USERS.REQUEST, getUsersIndexApiCall)

}
/**
 * ********* CHANGE_PASSWORD **********
 */
function* changePasswordIndexRequestedApiCall (action) {
  try {
    const result = yield call(Api.changePassword, action.payload)
    yield put({type: CHANGE_PASSWORD.SUCCESS, payload: result.data})

  }catch (e) {
    yield put({type: CHANGE_PASSWORD.FAILURE, payload: e.error || e})
  }
}

function* changePasswordIndexRequestedSaga() {
  yield takeEvery(CHANGE_PASSWORD.REQUEST, changePasswordIndexRequestedApiCall)
}
/*
  UPDATE AVATAR
*/
function* updateAvatarIndexApiCall(action){
  try{
    yield put({type:LOAD_AVATAR})
    let result = yield call(Api.uploadAvatar, action.payload)
    console.log("RESULT", result)
    yield put({type: UPLOAD_AVATAR.SUCCESS, payload: result})
    yield put({type: GET_USERS.REQUEST})
  }catch (e) {
    console.error(e)
  }
}

function* updateAvatarIndexSaga(){
  yield takeEvery(UPLOAD_AVATAR.REQUEST, updateAvatarIndexApiCall)
}
/*
  GET USERS FILES
*/
function* getUsersFilesApiCall(action){
  try{
    let result = yield call(Api.getUsersFiles)
    yield put({type: GET_USERS_FILES.SUCCESS, payload: result.data})
  }catch (e) {
    console.error(e)
  }
}
function* getUsersFiles(){
  yield takeEvery(GET_USERS_FILES.REQUEST, getUsersFilesApiCall)
}

/*
  UPLOAD FILE
*/
function* uploadFileApiCall(action){
  try{
    let result = yield call(Api.uploadFileToS3, action.payload)
    yield put({type: UPLOAD_FILE.SUCCESS})
    yield put({type: GET_USERS_FILES.REQUEST})
  }catch (e) {
    console.error(e)
  }
}
function* uploadFile(){
  yield takeEvery(UPLOAD_FILE.REQUEST, uploadFileApiCall)
}


/*
  CREATE OR UPDATE USER
*/
function* userUpdateIndexApiCall(action){
  try{
    let result = yield call(Api.updateUser, action.payload)
    switch(action.type){
      case UPDATE_USER.REQUEST:
        yield put({type: UPDATE_USER.SUCCESS, payload: result.data})
        break;
      case CREATE_OR_UPDATE_USER.REQUEST:
        yield put({type: CREATE_OR_UPDATE_USER.SUCCESS, payload: result.data})
    }
    yield put({type: GET_USERS.REQUEST})
    if (result.data.role === 'ADMIN') {
      yield put(push('/users-admin'))
    } else {
      yield put(push('/user-file'))
    }
  }catch (e) {

  }
}

function* useUpdateIndexSaga(){
  yield takeEvery(UPDATE_USER.REQUEST, userUpdateIndexApiCall)
  yield takeEvery(CREATE_OR_UPDATE_USER.REQUEST, userUpdateIndexApiCall)
}

/*
  GET USERS FILES
*/
function* downloadFile(action){
  try{
    const result = yield call(() => Api.downloadFile(action.payload))
    const url = result.data.url
    let link = document.createElement('a');
    link.href = url
    link.click();
  }catch (e) {
    console.error(e)
  }
}

function* downloadFileSaga(){
  yield takeEvery(DOWNLOAD_FILE, downloadFile)
}

/*
  DELETE USER FILE
*/
function* deleteFile(action){
  try{
    yield call(() => Api.deleteUserFile(action.payload))
    yield put({type: GET_USERS_FILES.REQUEST})
  }catch (e) {
    console.error(e)
  }
}

function* deleteFileSaga(){
  yield takeEvery(DELETE_FILE, deleteFile)
}

/*
  UPLOAD POPUP IMAGE
*/
function* uploadPopupImageApiCall(action){
  try{
    let result = yield call(Api.uploadPopupImage, action.payload)
    yield put({type: UPLOAD_POPUP_IMAGE.SUCCESS})
  }catch (e) {
    console.error(e)
  }
}
function* uploadPopupImage(){
  yield takeEvery(UPLOAD_POPUP_IMAGE.REQUEST, uploadPopupImageApiCall)
}


function* userIndexSaga () {
  yield spawn(useUpdateIndexSaga)
  yield spawn(getUsersIndexSaga)
  yield spawn(changePasswordIndexRequestedSaga)
  yield spawn(updateAvatarIndexSaga)
  yield spawn(getUsersFiles)
  yield spawn(uploadFile)
  yield spawn(downloadFileSaga)
  yield spawn(deleteFileSaga)
  yield spawn(uploadPopupImage)
}

export default userIndexSaga
