import React from 'react';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import {CircularProgress, Tooltip, makeStyles} from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonRoot: {
        '&:focus': {
            outline: 'none'
        }
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function CircularIntegration({ handleClick, uploadingFile, callBack, disabled }) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const buttonClassname = clsx({
        [classes.buttonRoot]: true,
        [classes.buttonSuccess]: success,
    });

    const handleButtonClick = () => {
        handleClick()
        if (!loading) {
            setLoading(true);
            setSuccess(false);
        }
    };

    React.useEffect(() => {
        if(!uploadingFile && loading) {
            setLoading(false);
            setSuccess(true);
            setTimeout(() => callBack(), 500)
        }
    }, [uploadingFile])

    return (
        <div className={classes.root}>
            <Tooltip title="Subir archivo" placement="right">
                <div className={classes.wrapper}>
                    <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        onClick={handleButtonClick}
                        disabled={disabled}
                    >
                        {success ? <CheckIcon /> : <SaveIcon />}
                    </Fab>
                    {loading && <CircularProgress size={68} className={classes.fabProgress} />}
                </div>
            </Tooltip>
        </div>
    );
}
