import React, {Component} from 'react'
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ButtonGroup,
  Button,
  Row,
  Col,
  Container,
} from "shards-react";
import CardFaq from "../components/Faq/Card";
import FormFaq from "../components/Faq/form";

import PageTitle from "../components/common/PageTitle";
class FaqView extends Component{
    constructor(props){
        super(props)
        this.onEdit = this.onEdit.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onClear = this.onClear.bind(this)
        this.check = this.check.bind(this)
        this.state ={
            item:{
                title:"",
                description:""
            },
            error:{
                title:false,
                description:false
            }
        }
    }

    componentDidMount () {
        let {itemsActions} = this.props
        itemsActions.selectType('faq')       
        itemsActions.addValue(this.state.item) 
    }

    componentDidUpdate(prevProps) {
        let {itemSelected, itemsActions, item} = this.props
        if (itemSelected && prevProps.itemSelected != itemSelected ){
            itemsActions.addValue(itemSelected)
        }

        if (!item.hasOwnProperty('title')){
            itemsActions.addValue(this.state.item) 
        }
    }
    componentWillUnmount(){
        this.props.itemsActions.clearType()
        this.props.itemsActions.clearItem()
    }

    onEdit (itemId) {
        let {itemsActions} = this.props
        itemsActions.selectItem(itemId)
    }
    onDelete (itemId) {
        let {itemsActions} = this.props
        itemsActions.deleteItem({itemId})
    }

    onChange (e) {
        let {itemsActions} = this.props
        itemsActions.addValue({[e.target.name]: e.target.value}) 
    }
    onClear (){
        this.props.itemsActions.clearItem()
    }
    check(){
        let {item} = this.props
        let error ={title:null, description:null}

        if (item.title === ""){
            error.title=true
        }else {
            error.title=false
        }
        if (item.description === ""){
            error.description=true
        }else {
            error.description=false
        }
        this.setState({error})
        if (error.title || error.description) return false
        return true
    }
    onSubmit () {        
        if (this.check()){
            let {itemsActions,item, type} = this.props
            itemsActions.createOrUpdate({item :{...item, typeId: type.typeId}})
        }
    }

    render(){
        let {items, item} = this.props
        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title="Pregunta Frecuentes" subtitle="" className="text-sm-left" />
                </Row>
                <Row>
                    <Col xs={6}>
                        {items.map((item, idx) => (
                            <div key={item.itemId} >
                                <CardFaq title={item.title} detail={item.description} itemId={item.itemId} onDelete={this.onDelete} onEdit={this.onEdit} />
                            </div>
                        ))}
                    </Col>
                    <Col xs={6}>
                        <FormFaq item={item} error={this.state.error} onClear={this.onClear} onChange={this.onChange} onSubmit={this.onSubmit} />
                    </Col>
                </Row>
            </Container>

           )
    }
}

export default FaqView