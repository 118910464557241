import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "../../common/components/Button";
import CalculatorNav from "./CalculatorNav";

export default ({
  setStep,
  prevStep,
  nextStep,
  step,
  setFormInfo,
  formInfo,
  formStepStyle,
  calculatorActions
}) => {
  const [validStep, setValidStep] = useState(true);
  const [disabled, setDisabled] = useState(true);

  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
      // width: 265
    },
    dense: {
      marginTop: 25
    },
    menu: {
      width: 265
    }
  }));
  const classes = useStyles();

  const validStepOne = value => {
    calculatorActions.setValues({ultimo_ejercicio:value})
    setFormInfo({ ...formInfo, ultimo_ejercicio: value });
    setValidStep(false);
    if (!value || value === "" || isNaN(value)) {
      setValidStep(false);
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (formInfo.ultimo_ejercicio !== "" && validStep) {
      setDisabled(false);
    }
  });

  return (
    <div class={`step ${formStepStyle}`}>
      <div className="step__nav">
        <CalculatorNav
          setStep={setStep}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          valid={!disabled}
        />
      </div>
      <div className="step__content">
        <div className="step__content__header">
          <TextField
            id="standard-helperText"
            label="Ingresá el Resultado de tu último ejercicio"
            defaultValue={formInfo.ultimo_ejercicio}
            className={classes.textField}
            helperText={disabled && !validStep && "Debes ingresar solo numeros"}
            margin="normal"
            error={!validStep && disabled}
            onChange={e => validStepOne(e.target.value)}
            onKeyPress={e => {
              if (e.key === "Enter" && !disabled) {
                setStep(nextStep(step));
              }
            }}
          />
        </div>
        <div className="step__content__footer">
          <div className="step__content__footer__wrapper">
            <Button
              label="OK"
              type="contain"
              onClick={() => setStep(nextStep(step))}
              icon="check"
              iconLast={true}
              disabled={disabled}
            />
            <p>presióna ENTER</p>
          </div>
        </div>
      </div>
    </div>
  );
};
