import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Contact from '../../../components/landing/Contact'
import {  contactUsSelectors, contactUsActions} from '../../../state/redux/contact_us'
import { getContactFirst } from '../../../state/redux/contact_us/selectors';

const { isLoad, contact} = contactUsSelectors

const mapStateToProps = state => ({
    isLoad: isLoad(state),
    contact: getContactFirst(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(contactUsActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact)
