import { createSelector } from 'reselect'

export const getItems = state => state.items.items
export const getItemSelected =  state =>  state.items.selected
export const getTypeSelected =  state =>  state.items.selectedType
export const getItem = state =>  state.items.item
export const getTypes = state =>  state.items.types

export const getItemsSelected = createSelector([getItems, getTypeSelected] , (items, selected) => {
  return items.filter(i => i.type === selected) || []
})

export const getObjectItemSelected = createSelector([getItemsSelected, getItemSelected], (items, selected) => {
  return items.find(i => i.itemId === selected) || null
})

export const getPopup = createSelector([getItems,getTypes], (items, types) => {
  let item = items.find(i => i.type === 'popup')
  console.log("ITEM", item)
  return item ? item: {title:'', image:''}
})

export const getObjectTypeSelected = createSelector([getTypes, getTypeSelected] , (types, selected) => {
  return types.find(i => i.name === selected) || null
})


export const getSortItemsBytype = createSelector([getTypes, getItems], (types, items) => {
  let result={}
  types.forEach(t => {
    result[t.name]= items.filter(i => i.typeId == t.typeId)
  });

  return result
}) 
