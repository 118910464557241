import React from 'react'
import {
    GoogleMap,
    Marker,
    useLoadScript
  } from '@react-google-maps/api'

import Spinner from './spinner'



const Map = ({lat, lng, mapStyle, isLoaded}) => {
    
    mapStyle = mapStyle || {
        height: "400px",
        width: "100%"
    }
    const onClick= () => {}
    const onMapLoad= () => {}
    let mapPosition= {
        lat: lat || -34.6042329,
        lng: lng || -58.4264253
    }
   
    let marker = lat && lng ? {lat,lng} : null
    return (
        <div className='map-container'>
            
           
                <GoogleMap
                    id='data-example'
                    mapContainerStyle={mapStyle}
                    zoom={10}
                    center={mapPosition}
                    onClick={onClick}
                    onLoad={onMapLoad}
                    >
                    {
                        marker ?
                            <Marker
                                position={{
                                    lat: marker.lat,
                                    lng: marker.lng
                                }}
                            />
                            :""
                    }
                </GoogleMap>
         
        </div>
    )
}


export default Map