import { createRequestTypes } from '../../../utils/parse'

const namespace = 'session'


const LOGIN = createRequestTypes(`${namespace}/LOGIN`)
const LOGOUT = createRequestTypes(`${namespace}/LOGOUT`)
const GET_USER = createRequestTypes(`${namespace}/GET_USER`)
const SAVE_TOKEN = `${namespace}/SAVE_TOKEN`
const REMOVE_TOKEN = `${namespace}/REMOVE_TOKEN`
const CHANGE_USER = `${namespace}/CHANGE_USER`
const UPDATE_USER = createRequestTypes(`${namespace}/UPDATE_USER`)
const FORGOT_PASSWORD = createRequestTypes(`${namespace}/FORGOT_PASSWORD`)
const RESET_PASSWORD = createRequestTypes(`${namespace}/RESET_PASSWORD`)
const RESTART = `RESTART`
export default {
  LOGIN,
  LOGOUT,
  GET_USER,
  SAVE_TOKEN,
  REMOVE_TOKEN,
  UPDATE_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESTART,
  CHANGE_USER
}
