import React from "react";
import PropTypes from "prop-types";
import { Col } from "shards-react";


const Spinner = ({type, spi, style }) => {
  const color = {
    'primary': 'text-primary',
    'secondary': 'text-secondary',
    'success': 'text-success',
    'danger': 'text-danger',
    'warning: ': 'text-warning',
    'info': 'text-info',
    'light': 'text-light',
    'dark': 'text-dark',
  }
  return (
    <React.Fragment >
       { 
        !spi ? 
        <Col xs="12" sm="4" className="mx-auto text-center"  >
        <div className={`spinner-border ${type ? color[type] : color['primary']}`} role="status">
          <span className="sr-only">Loading...</span>
          </div>
        </Col>
      : 
      <div className={`spinner-border ${type ? color[type] : color['primary']}`} style={style} role="status">
        <span className="sr-only">Loading...</span>
      </div>
      }
    </React.Fragment>
  
  )
};

Spinner.propTypes = {

};

export default Spinner;
