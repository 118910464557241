import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import { withRouter } from "react-router";
import Spinner from "../components/common/spinner"
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../containers/layout/MainSiderBarContainer";
import MainFooter from "../components/layout/MainFooter";

class DefaultLayout extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillMount (){
    let {sessionActions, user} = this.props
    if (!user || !user.name){
      sessionActions.getUserRequest()
    }

  }

  render (){
    let { children, noNavbar, noFooter, user, isLoadSession, sessionActions, sideNavBarActions } = this.props

    return (
      <Container fluid>

          <Row>
            <MainSidebar hideLogoText={true}/>
            <Col
              className="main-content p-0"
              lg={{ size: 10, offset: 2 }}
              md={{ size: 9, offset: 3 }}
              sm="12"
              tag="main"
            >
              {!noNavbar && <MainNavbar user={user} sessionActions={sessionActions} sideNavBarActions={sideNavBarActions} />}
              {!isLoadSession ?

                children
                :
                <Row className="align-middle min-vh-100" style={{'alignItems':'center'}}>
                  <Col sm={{ size: 4, order: 4, offset: 4 }} >
                    <Spinner />
                  </Col>
                </Row>
              }
              {!noFooter && <p />}
            </Col>
          </Row>


        
      </Container>
    )
  }
}



DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
