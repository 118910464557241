import React from "react";
// Layout Types
import { LoginLayout, LandingLayout } from "./layouts";
import DefaultLayout from "./containers/admin/DefaultLayoutContainer"


// Route Views
import UserProfileLite from "./containers/views/UserProfileLiteContainer";
import Users from "./containers/views/UsersContainer";
import UserDetail from "./containers/views/UserDetailContainer"
import LoginContainer from "./containers/admin/LoginContainer"
import FaqLandingContainer from "./containers/landings/faqContainer"
import ClientContainer from "./containers/views/ClientContainer"
import HowToContainer from "./containers/views/HowToContainer"
import CategoryContainer from "./containers/views/CategoryContainer.js"
import CategoryEditContainer from "./containers/views/CategoryEditContainer"
import ContactUsContainer from "./containers/views/ContactUsContainer"
import ContactContainer from "./containers/landings/ContactContainer"
import HomeView from "./landings/HomeView"
import ServicesView from "./landings/ServicesView"
import AboutView from "./landings/AboutView"
import LandingLayoutContainer from "./containers/admin/LandingLayoutContainer";
import CalculatorView from "./landings/CalculatorView";
import FaqContainer from "./containers/views/FaqContainer";
import DashboardContainer from "./containers/views/DashboardContainer";
import PopupEditContainer from "./containers/views/PopupEditContainer";
import CalculatorBannerContainer from "./containers/landings/CalculatorBannerContainer";
import WeightContainer from "./containers/views/WeightContainer";
import UserFilesContainer from "./containers/views/UserFilesContainer";
import MainPopupContainer from "./containers/views/MainPopupContainer";

export default [
  {
    path: "/",
    exact: true,
    layout: LandingLayoutContainer,
    component: HomeView
  },
  {
    path: "/services",
    exact: true,
    layout: LandingLayoutContainer,
    component: ServicesView
  },
  {
    path: "/about-us",
    exact: true,
    layout: LandingLayoutContainer,
    component: AboutView
  },
  {
    path: "/calculator",
    exact: true,
    layout: LandingLayoutContainer,
    component: CalculatorBannerContainer
  },
  {
    path: "/faq",
    layout: LandingLayoutContainer,
    component: FaqLandingContainer
  },
  {
    path: "/contact/",
    layout: LandingLayoutContainer,
    component: ContactContainer
  },
  {
    path: "/contact-us/",
    layout: DefaultLayout,
    component: ContactUsContainer
  },
  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: DashboardContainer
  },
  {
    path: "/weight",
    exact: true,
    layout: DefaultLayout,
    component: WeightContainer,
  },
  {
    path: "/admin/",
    layout: LoginLayout,
    component: LoginContainer,
  },
  {
    path: "/faq-admin",
    layout: DefaultLayout,
    component: FaqContainer
  },
  {
    path: "/how-to/",
    layout: DefaultLayout,
    component: HowToContainer
  },
  {
    path: "/clients/",
    layout: DefaultLayout,
    component: ClientContainer,
  },
  {
    path: "/categories/",
    layout: DefaultLayout,
    component: CategoryContainer,
  },
  {
    path: "/category/edit",
    layout: DefaultLayout,
    component: CategoryEditContainer,
  },
  {
    path: "/popup/edit",
    layout: DefaultLayout,
    component: PopupEditContainer,
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/users-admin",
    layout: DefaultLayout,
    component: Users,
  },
  {
    path: "/user-detail",
    layout: DefaultLayout,
    component: UserDetail
  },
  {
    path: "/add-new-user",
    layout: DefaultLayout,
    component: UserProfileLite,
    props: {
      newUser: true
    },
  },
  {
    path: "/user-file",
    layout: DefaultLayout,
    component: UserFilesContainer,
  },
  {
    path: "/popup",
    layout: DefaultLayout,
    component: MainPopupContainer,
  },
];
