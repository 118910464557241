import React from 'react'
import * as Scroll from "react-scroll";
let Link = Scroll.Link;

export default ({ title1, title2, subtitle, action, resetForm }) => (
  <div class="step-zero">
    {title1 && <h2 className="step__title">{title1}</h2>}
    {title2 && <h2 className="step__title">{title2}</h2>}
    {subtitle && <h3 className="step__title">{subtitle}</h3>}
    {action && (
      <Link
        className="btn-primary"
        activeClass="active"
        to="step1"
        spy={true}
        smooth={true}
        offset={50}
        duration={1000}
        onClick={() => resetForm()}
      >
        <span>{action.label}</span>
      </Link>
    )}
  </div>
);
