import { createRequestTypes } from '../../../utils/parse'

const namespace = 'sidebarMainNavbar'

const CHANGE = `${namespace}/CHANGE`
const TOGGLE_SIDEBAR= `${namespace}/TOGGLE_SIDEBAR`
const ACTIVE_MENU= `${namespace}/ACTIVE_MENU`

export default {
  CHANGE,
  TOGGLE_SIDEBAR,
  ACTIVE_MENU
}
