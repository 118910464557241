import DashboardContainer from "../containers/views/DashboardContainer";

export default function() {
  return [
    {
      title: "Dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      to: "/dashboard",
      protected: true,
    },
    {
      title: "Preguntas Frecuentes",
      htmlBefore: '<i class="material-icons">question_answer</i>',
      to: "/faq-admin",
      protected: true,
    },
    {
      title: "Rangos Calulador",
      htmlBefore: '<i class="material-icons">keyboard</i>',
      to: "/weight",
      protected: true,
    },
    {
      title: "Contactenos",
      htmlBefore: '<i class="material-icons">where_to_vote</i>',
      to: "/contact-us",
      protected: true,
    },
    {
      title: "Partners",
      htmlBefore: '<i class="material-icons">work</i>',
      to: "/clients",
      protected: true,
    },
    {
      title: "Rubros",
      htmlBefore: '<i class="material-icons">add_to_photos</i>',
      to: "/categories",
      protected: true,
    },
    {
      title: "Usuarios",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/users-admin",
      protected: true,
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    },
    {
      title: "Archivos",
      htmlBefore: '<i class="material-icons">attach_file</i>',
      to: "/user-file",
    },
    {
      title: "Popup",
      htmlBefore: '<i class="material-icons">perm_media</i>',
      to: "/popup",
      protected: true,
    },
  ];
}
