import React, { useState } from "react";
import Button from "../../common/components/Button";
import Fade from "react-reveal/Fade";
import useForm from "react-hook-form";
import "./Subscribe.scss";

export default ({actions}) => {
  const title = "Recibí todas las novedades";
  const [mail, setMail] = useState("");
  const { register, handleSubmit, errors, reset } = useForm();
  const handlerClick = () => {
    onSubmit();
  };

  const onSubmit = data => {
    actions.call({
      name: data.email,
      email: data.email,
      message:"Me quiero subscribir a las novedades."
    })
    reset()
  };

  const inputStyle = errors.email
    ? "subscribe-home__input input-rounded input-error"
    : "subscribe-home__input input-rounded";

  return (
    <Fade>
      <section className="subscribe-home">
        <div className="subscribe-home__title">
          <h2>{title}</h2>
        </div>
        <div className="subscribe-home__form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className={inputStyle}
              type="text"
              placeholder="Ingresá tu Email"
              name="email"
              ref={register({
                required: true,
                maxLength: 80,
                pattern: {
                  value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                }
              })}
            />
            <Button
              label="SUSCRIBIRSE"
              type="contain"
              onClick={() => handlerClick()}
              disabled={mail !== "" && true}
            />
          </form>
        </div>
      </section>
    </Fade>
  );
};
