import React, { Component } from "react";
import "./style.css";
import { withRouter } from "react-router-dom";
import Box from "../box";
import Slider from "../../slider";
import Fade from "react-reveal/Fade";
import ReactResizeDetector from "react-resize-detector";
const items = [
  {
    title: "Trayectoria",
    text: "Contamos con amplia experiencia en el otorgamiento de avales.",
    src: require("../../../images/icons/laptop.svg")
  },
  {
    title: "Transparencia",
    text: "La empresa despeja las dudas a la hora de empezar un negocio.",
    src: require("../../../images/icons/handshake.svg")
  },
  {
    title: "Innovación",
    text:
        "Utilizamos las últimas herramientas financieras para mejorar el servicio.",
    src: require("../../../images/icons/cloud-computing.svg")
  }
];
class Banner extends Component {
  constructor(props) {
    super(props);
    this.onResize = this.onResize.bind(this);
    this.state = {
      props: {},
      config: {
        dotsDisabled: false
      },

      responsive: {
        0: { items: 1 },
        768: { items: 2 },
        1024: { items: 3 }
      }
    };
  }
  onResize(res) {
    console.log(res);
    if (res > 767) {
      this.setState({ config: { ...this.state.config, dotsDisabled: true } });
    } else {
      this.setState({ config: { ...this.state.config, dotsDisabled: false } });
    }
  }

  sendTo = to => {
    this.props.history.push(to);
    window.scrollTo(0, 0);
  };

  render() {
    let { config, responsive, props } = this.state;

    return (
        <section className="banner">
          <ReactResizeDetector
              handleWidth
              handleHeight
              onResize={this.onResize}
          />
          <Fade left>
            <div className="head">
              <div className="title-head">
                Construímos el puente que necesitas para <span className="title-subtitle">seguir adelante.</span>
              </div>
              <div className="sub-title-head">
                Facilitamos el acceso de tu PYME al crédito. Acompañamos tu
                crecimiento.
              </div>
              <div className="btn-group">
                <button
                    className="btn-home"
                    onClick={() => this.sendTo("/about-us")}
                >
                  CONOCENOS
                </button>
                <button
                    className="btn-home btn-white-blue"
                    onClick={() => this.sendTo("calculator")}
                >
                  CALCULÁ TU AVAL
                </button>
              </div>
            </div>
          </Fade>
          <Slider
              items={items}
              classContainer="float-bottom"
              config={config}
              Component={Box}
              propsComponent={props}
              responsive={responsive}
          ></Slider>
        </section>
    );
  }
}

export default withRouter(Banner);
