import React from "react";
import AliceCarousel from "react-alice-carousel";
import Fade from "react-reveal/Fade";
import "react-alice-carousel/lib/alice-carousel.css";
import "./style.scss";
import {Button, Modal, ModalHeader, ModalBody} from 'shards-react'
const whatWeDo = "¿QUÉ HACEMOS?";
const whatWeDoAnswer1 = "Servicios ";
const whatWeDoAnswer2 = "financieros ";
const whatWeDoAnswer3 = "para PYMEs";
const leyend = "Somos un equipo comprometido con el desarrollo de tu empresa.";



export const Slider = ({ slide, key, onClick }) => (
  <React.Fragment>
    <div
      className="services-home__carousel__item"
      key={`services-home__carousel__${key}`}
      onClick={ ()  =>onClick()}
    >
      <div
        className="services-home__carousel__item__image"
        style={{ backgroundImage: `url(${slide.image})` }}
      ></div>
      <div className="services-home__carousel__item__info">
        <h3>{slide.title}</h3>
        <p>{slide.description}</p>
      </div>
    </div>
    </React.Fragment>



  
);

class Services extends React.Component {
  constructor(props){
    super(props)
    this.state={
      responsive : { 767: { items: 1 }, 1024: { items: 3 } },
      show: false
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.galleryItems = this.galleryItems.bind(this)
  }
  componentDidMount () {
    let {itemsActions} = this.props
    itemsActions.selectType('category')
  }

  galleryItems() {
    let {items} = this.props
    return items.map((slide, key) => (
      <Slider slide={slide} key={key} onClick={this.handleShow}/>
    )); 
  } 


  handleClose(){  this.setState({show: false});}
  handleShow (){this.setState({show: true});}
  render() {
    let {popup} = this.props
    console.log("POPUP", popup)
    return (
      <React.Fragment>
        <section className="service services-home">
          <Fade bottom>
            <div className="services-home__info">
              <h3>{whatWeDo}</h3>
              <h4>{whatWeDoAnswer1}</h4>
              <h4>{whatWeDoAnswer2}</h4>
              <h4>{whatWeDoAnswer3}</h4>
              <p>{leyend}</p>
              <div className="slider-controls">
                <span onClick={() => this.Carousel.slidePrev()}>
                  <i className="fas fa-chevron-left"></i>
                </span>
                <span onClick={() => this.Carousel.slideNext()}>
                  <i className="fas fa-chevron-right"></i>
                </span>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="services-home__carousel">
              <div className="services-home__carousel__blue"></div>
              <div className="infitivescroll">
                <AliceCarousel
                  dotsDisabled={false}
                  buttonsDisabled={true}
                  responsive={this.state.responsive}
                  items={this.galleryItems()}
                  ref={el => (this.Carousel = el)}
                  />
              </div>
            </div>
          </Fade>
        </section>
      
        <Modal open={this.state.show} toggle={this.handleClose} >
          <ModalBody>
            <img className="img-fluid"  src={popup && popup.image ? popup.image : ""}></img>
          </ModalBody>
        </Modal>
      </React.Fragment>

    );
  }
}

export default Services;
