import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UsersView from '../../views/Users'
import { userActions, userSelectors} from '../../state/redux/user'
const { getUserSelected, getUsers} = userSelectors

const mapStateToProps = state => ({
  users: getUsers(state),
  userSelected: getUserSelected(state)
})

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersView)
