import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Button,ButtonGroup } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Moment from 'react-moment';
import 'moment-timezone';

class Users extends React.Component {
  constructor(props){
    super(props);
    this.onSelectedUser = this.onSelectedUser.bind(this)
    this.onAddUser = this.onAddUser.bind(this)
    this.onViewDetailUser = this.onViewDetailUser.bind(this)
    this.onEditUser = this.onEditUser.bind(this)
  }
  componentDidMount(){
    let {users, userActions} = this.props
    if (users.length === 0){
      userActions.getUsersRequest()
    }
  }
  onSelectedUser (userId){
    let {userActions} = this.props
    if (userId){
      userActions.selectUser(userId)
    }
  }

  onAddUser(){
    let {history} = this.props
    history.push('/add-new-user')
  }

  onViewDetailUser (userId){
    let {history} = this.props
    this.onSelectedUser(userId)
    history.push('/user-detail')
  }

  onEditUser(userId){
    let {history} = this.props
    this.onSelectedUser(userId)
    history.push('/user-profile-lite')
  }

  render(){
    let {users} = this.props
    return(
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Usuarios" subtitle="Managment Users" className="text-sm-left" />
        </Row>
        <Row >
          <Col className="mb-4"><Button onClick={this.onAddUser} pill theme="success"><i class="material-icons">person_add</i> Agregar usuario</Button> </Col>
        </Row>
        {/* Default Light Table */}
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Active Users</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Nombre
                      </th>
                      <th scope="col" className="border-0">
                        Apellido
                      </th>
                      <th scope="col" className="border-0">
                        Documento
                      </th>
                      <th scope="col" className="border-0">
                        Nacimiento
                      </th>
                      <th scope="col" className="border-0">
                        Email
                      </th>
                      <th scope="col" className="border-0">
                        Télefono
                      </th>
                      <th scope="col" className="border-0">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    users.map((u) => (
                      <tr key={u.userId}>
                        <td>1</td>
                        <td>{u.name}</td>
                        <td>{u.lastname}</td>
                        <td>{u.dni}</td>
                        <td><Moment format="DD/MM/YYYY" tz="America/Argentina/Rio_Gallegos">{new Date(u.birthDay)}</Moment></td>
                        <td>{u.email}</td>
                        <td>{u.phone}</td>
                        <td>
                        <ButtonGroup size="sm" >
                          <Button theme="info" style={{'margin-right':0}} onClick={()=> this.onEditUser(u.userId)} ><i className="material-icons">edit</i></Button>
                          <Button theme="info" onClick={()=> this.onViewDetailUser(u.userId)}  ><i className="material-icons">visibility</i></Button>
                        </ButtonGroup>
                      </td>
                      </tr>

                    ))
                  }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    )
  }
}



export default Users;
