import React from "react";
import Zoom from "react-reveal/Zoom";
import "./ServiceHowTo.scss";

const howToImg = "./images/services/how-to.jpg";
const howToContent = [
  {
    icon: "./images/services/bank.png",
    title: "Bancos",
    description: ["Líneas de crédito bancarios.", "Préstamos productivos."]
  },
  {
    icon: "./images/services/market.png",
    title: "Mercado de capitales",
    description: [
      "Descuento de cheques de pago diferido.",
      "Obligaciones negociables."
    ]
  },
  {
    icon: "./images/services/provider.png",
    title: "Proveedores comerciales",
    description: ["Líneas de crédito comercial."]
  }
];

export default () => (
  <section className="services-how-to">
    <Zoom cascade>
      <div className="services-how-to__image">
        <img src={howToImg} ale="how-to" />
      </div>
    </Zoom>
    <Zoom right>
      <div className="services-how-to__text">
        <h2>¿CÓMO PUEDO ACCEDER A UN AVAL?</h2>
        {howToContent.map((how, key) => (
          <div className="services-how-to__card" key={`how-${key}`}>
            <div className="services-how-to__card__icon">
              <span>
                <img src={how.icon} alt={how.title} />
              </span>
            </div>
            <div className="services-how-to__card__texts">
              <h3>{how.title}</h3>
              {how.description.map((p, pkey) => (
                <p key={`p-${pkey}`}>{p}</p>
                ))}
            </div>
          </div>
        ))}
      </div>
    </Zoom>
  </section>
);
