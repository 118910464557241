import React from "react";
import Zoom from "react-reveal/Zoom";
import "./ServiceWarrantyTypes.scss";

const warranties = [
  {
    label: "Garantías Bancarias",
    content: "Accedé a créditos a través del sistema financiero",
    icon: "./images/services/transparencia.png"
  },
  {
    label: "Garantías Bursátiles",
    content: "El mejor financiamiento en el mercado bursátil.",
    icon: "./images/services/transparencia.png"
  },
  {
    label: "Garantías Comerciales",
    content: "Ampliá el crédito con tus empresas proveedoras.",
    icon: "./images/services/transparencia.png"
  }
];
export default () => (
  <Zoom>
    <section className="services-warranty-types">
      <div className="services-warranty-types__list">
        {warranties.map((warrant, key) => (
          <div className="services-warranty-types__list__item">
            <div className="services-warranty-types__list__item__img">
              <img src={warrant.icon} alt={warrant.label} />
            </div>
            <h3>{warrant.label}</h3>
            <h4>{warrant.content}</h4>
          </div>
        ))}
      </div>
    </section>
  </Zoom>
);
