import {takeEvery, call, put, spawn, select} from 'redux-saga/effects';
import types from '../redux/calculator/types'
import Api from '../../services/api';

const {VALIDATE_CUIT, CALCULATOR, GET_CALCULATOR, SET_WEIGHT, GET_WEIGHT} = types



function* getCalculatorIndexApiCall(action){
  try{
    let result = yield call(Api.getCalculator, action.payload)
    yield put({type:GET_CALCULATOR.SUCCESS, payload: result.data})  
  }catch (e) {

  }
}

function* getCalculatorIndexSaga(){
  yield takeEvery(GET_CALCULATOR.REQUEST, getCalculatorIndexApiCall)  
}



function* getWeightIndexApiCall(action){
  try{
    let result = yield call(Api.getWeight)
    yield put({type:GET_WEIGHT.SUCCESS, payload: result.data})  
  }catch (e) {

  }
}

function* getWeightIndexSaga(){
  yield takeEvery(GET_WEIGHT.REQUEST, getWeightIndexApiCall)  
}

function* setWeightIndexApiCall(action){
  try{
    let result = yield call(Api.setWeight, action.payload)
    yield put({type:SET_WEIGHT.SUCCESS, payload: result.data.response})  
  }catch (e) {

  }
}

function* setWeightIndexSaga(){
  yield takeEvery(SET_WEIGHT.REQUEST, setWeightIndexApiCall)  
}

function* calculateIndexApiCall(action){
    try{
      let result = yield call(Api.calculateNosis, action.payload)
      yield put({type:CALCULATOR.SUCCESS, payload: result.data})  
    }catch (e) {
  
    }
  }
  
function* calculateIndexSaga(){
    yield takeEvery(CALCULATOR.REQUEST, calculateIndexApiCall)  
}


function* validateCuitIndexApiCall(action){
    try{
      let result = yield call(Api.getValidateNosis, action.payload)
      yield put({type:VALIDATE_CUIT.SUCCESS, payload: result.data})  
    }catch (e) {
  
    }
  }
  
function* validateCuitIndexSaga(){
    yield takeEvery(VALIDATE_CUIT.REQUEST, validateCuitIndexApiCall)  
}

function* calculatorIndexSaga () {
    yield spawn(validateCuitIndexSaga)
    yield spawn(calculateIndexSaga)
    yield spawn(getCalculatorIndexSaga)
    yield spawn(setWeightIndexSaga)
    yield spawn(getWeightIndexSaga)

}
  
  export default calculatorIndexSaga