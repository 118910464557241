import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CalculatorBanner from "../../components/Calculator/CalculatorBanner";
import {  calculatorSelectors, calculatorActions} from '../../state/redux/calculator'
const { getValues, getValidate, getCalculate, getLoading} = calculatorSelectors

const mapStateToProps = state => ({
  payload: getValues(state),
  validate: getValidate(state),
  calculate: getCalculate(state),
  loading : getLoading(state)
})

const mapDispatchToProps = dispatch => ({
    calculatorActions: bindActionCreators(calculatorActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorBanner)
