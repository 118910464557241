import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";


class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navItems: []
    };
  }

  componentWillMount() {
    let {items} = this.props
    this.setState({navItems: items})
  }

  render() {
    const { navItems: items } = this.state;
    return (
        <div className="nav-wrapper">
          <Nav className="nav--no-borders flex-column">
            {items.filter(item => {
              if (item.protected) {
                return this.props.user.role === 'ADMIN'
              }
              return item
            }).map((item, idx) => (
                <SidebarNavItem key={idx} item={item} />
            ))}
          </Nav>
        </div>
    )
  }
}

export default SidebarNavItems;
