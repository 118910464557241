import React from "react";
import AboutBanner from "../components/About/AboutBanner";
import AboutWho from "../components/About/AboutWho";
import AboutHistory from "../components/About/AboutHistory";
import BanksSlider from "../containers/landings/ClientContainer";
import AboutHow from "../components/About/AboutHow";

export default () => (
  <>
    <AboutBanner />
    <AboutWho />
    <AboutHistory />
    <BanksSlider />
    <AboutHow />
  </>
);
