import types from "./types";

const initialState = {
  payload: {},
  validate: null,
  response: {},
  calculators: [],
  load: false,
  weight: null
};

const calculator = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEIGHT.SUCCESS:
    case types.SET_WEIGHT.SUCCESS:
      return {
        ...state,
        weight: action.payload
      };
    case types.SET_VALUES:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload
        }
      };
    case types.VALIDATE_CUIT.SUCCESS:
      return {
        ...state,
        validate: action.payload,
        load: false
      };
    case types.GET_CALCULATOR.SUCCESS:
      return {
        ...state,
        calculators: action.payload,
        load: false
      };
    case types.CALCULATOR.SUCCESS:
      return {
        ...state,
        response: action.payload,
        load: false
      };
    case types.VALIDATE_CUIT.REQUEST:
    case types.CALCULATOR.REQUEST:
    case types.GET_CALCULATOR.REQUEST:
      return {
        ...state,
        load: true
      };
    default:
      return state;
  }
};

export default calculator;
