import React from 'react'
import { StandaloneSearchBox, ScriptLoaded } from '@react-google-maps/api'
import {
    FormInput,
    InputGroupAddon,
    InputGroup,
    InputGroupText
    } from "shards-react";


  class InputAutoComplete extends React.Component {
    constructor (props) {
      super(props)
  
      this.autocomplete = null
  
      this.onLoad = this.onLoad.bind(this)
      this.onPlaceChanged = this.onPlaceChanged(this)
    }
  
    onLoad (re) {
      this.autocomplete = re
    }
  
    onPlaceChanged () {
      if (this.autocomplete !== null) {
        console.log(this.autocomplete.getPlace())
      } else {
        console.log('Autocomplete is not loaded yet!')
      }
    }
  
    render () {
        let {item, onChange} = this.props
      return (

          
            <StandaloneSearchBox
              onLoad={this.onLoad}
              onPlacesChanged={this.onPlaceChanged}
            >
             <InputGroup className="mb-3">
                <InputGroupAddon type="prepend">
                    <InputGroupText><i className="material-icons">room</i></InputGroupText>
                </InputGroupAddon>
                <FormInput
                    id="autocomplete"
                    name="address"
                    value={item ? item.address : ""}
                    placeholder="Ingrese su dirección"
                    onChange={onChange}
                />
            </InputGroup>
     
            </StandaloneSearchBox>

      )
    }
  }
  
export default InputAutoComplete