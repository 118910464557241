import React from "react";
import {
  Card,
  CardBody,
  ButtonGroup,
  Button,
  Row,
  Form,
  Col,
  FormInput,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from "shards-react";
import InputAutoComplete from "../common/FieldInputautoComplete";
import * as Icons from "@fortawesome/free-brands-svg-icons";
import * as FasIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormContactUs = ({
  item,
  onSubmit,
  onChange,
  onClear,
  error,
  isLoaded,
}) => {
  return (
    <Card small className="blog-comments mb-2">
      <CardBody className="">
        {item ? (
          <Form>
            <Row form>
              {/* Pregnta frecuente */}
              <Col md="12" className="form-group">
                <label htmlFor="autocomplete">Dirección</label>
                <InputAutoComplete item={item} onChange={onChange} />
              </Col>
              <Col md="12" className="form-group">
                <label htmlFor="phone">Télefono</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={FasIcons.faPhone} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    id="phone"
                    name="phone"
                    value={item ? item.phone : ""}
                    placeholder="Ingrese su dirección"
                    onChange={onChange}
                  />
                </InputGroup>
              </Col>
              <Col md="12" className="form-group">
                <label htmlFor="phone">Whatsapp</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={Icons.faWhatsapp} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    id="whatsapp"
                    name="whatsapp"
                    value={item ? item.whatsapp : ""}
                    placeholder="Ingrese su Whatsapp"
                    onChange={onChange}
                  />
                </InputGroup>
              </Col>

              {/* Respuesta a pregunta */}
              <Col md="12" className="form-group">
                <label htmlFor="email">Email</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={FasIcons.faAt} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Su Email"
                    value={item ? item.email : ""}
                    onChange={onChange}
                  />
                </InputGroup>
              </Col>
              <Col md="12" className="form-group">
                <label htmlFor="facebook">Facebook</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={Icons.faFacebookF} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="url"
                    id="facebook"
                    name="facebook"
                    placeholder="Su Facebook"
                    value={item ? item.facebook : ""}
                    onChange={onChange}
                  />
                </InputGroup>
              </Col>
              <Col md="12" className="form-group">
                <label htmlFor="LinkedIn">LinkedIn</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={Icons.faLinkedinIn} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="url"
                    id="linkedin"
                    name="linkedin"
                    placeholder="Su Linkedin"
                    value={item ? item.linkedin : ""}
                    onChange={onChange}
                  />
                </InputGroup>
              </Col>
              <Col md="12" className="form-group">
                <label htmlFor="instagram">Instagram</label>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={Icons.faInstagram} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="url"
                    id="instagram"
                    name="instagram"
                    placeholder="Su instagram"
                    value={item ? item.instagram : ""}
                    onChange={onChange}
                  />
                </InputGroup>
              </Col>
            </Row>

            {/* Content :: Actions */}
            <div className="m-1 f-r blog-comments__actions">
              <ButtonGroup size="sm">
                <Button
                  theme="white"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  <span className="text-light">
                    <i className="material-icons">edit</i>
                  </span>{" "}
                  Guardar
                </Button>
              </ButtonGroup>
            </div>
          </Form>
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
};

export default FormContactUs;
