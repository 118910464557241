import React from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../components/common/spinner"
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

library.add(faLock);

class LoginView extends React.Component{
  constructor (props){
    super(props)
    this.state={
      email:'',
      password:''
    }
    this.onChange = this.onChange.bind(this)
    this.onLogin = this.onLogin.bind(this)
  }
  onChange (input){
    this.setState({...this.state, [input.target.name]: input.target.value})
  }

  onLogin(){
    const {sessionActions} = this.props
    sessionActions.loginRequest(this.state)
  }

  render(){
    let {isLogin, isLoadSession, isError, messageError} = this.props

    return (
        <Container fluid className="main-content-container min-vh-100">
          {/* Page Header */}
          <Row className="align-middle min-vh-100" style={{'alignItems':'center'}}>
            <Col sm={{ size: 4, order: 4, offset: 4 }} >
              <Card   className="mx-auto ">
                <CardHeader>
                  <CardTitle className="text-center" tag="h3">Login</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>@</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                            invalid={isError}
                            placeholder="Email o CUIT (sin guiones) o Usuario"
                            size="lg"
                            onChange={this.onChange}
                            name="email"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText><FontAwesomeIcon icon={["fas", "lock"]} /></InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                            invalid={isError}
                            type="password"
                            placeholder="Password"
                            size="lg"
                            name="password"
                            onChange={this.onChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Form>
                  {
                    !isLoadSession ?
                        <Button active={!isLogin} onClick={this.onLogin} disabled={isLoadSession ? 'disabled': null} theme="success"  className="d-block mx-auto px-5">
                          Login
                        </Button>
                        :
                        <Spinner />
                  }
                </CardBody>

              </Card>
            </Col>

          </Row>


        </Container>
    )

  }

}




export default LoginView;
