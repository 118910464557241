import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FaqView from '../../views/FaqView'
import {  itemsSelectors, itemsActions} from '../../state/redux/items'


const { getItem, getItemsSelected, getObjectTypeSelected, getObjectItemSelected} = itemsSelectors

const mapStateToProps = state => ({
  items: getItemsSelected(state),
  type: getObjectTypeSelected(state),
  item: getItem(state),
  itemSelected: getObjectItemSelected(state)
})

const mapDispatchToProps = dispatch => ({
  itemsActions: bindActionCreators(itemsActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqView)
