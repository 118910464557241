import types from './types'

const setValues = (payload) => ({
    type: types.SET_VALUES,
    payload
 })


const setWeight = payload => ({
    type: types.SET_WEIGHT.REQUEST,
    payload
})

const getWeight = () => ({
    type: types.GET_WEIGHT.REQUEST
})

const validateCuit = (payload) => ({
    type: types.VALIDATE_CUIT.REQUEST,
    payload
 })

const calculate = (payload) => ({
    type: types.CALCULATOR.REQUEST,
    payload
 })

 const getCalculators = () => ({
    type: types.GET_CALCULATOR.REQUEST
 })
 

 export default{
     setValues,
     validateCuit,
     calculate,
     getCalculators,
     setWeight,
     getWeight
 }