import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from './redux';
import mySaga from './saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux'
import {createBrowserHistory} from 'history'

const composeEnhancers = composeWithDevTools({ realtime: false });

export const history = createBrowserHistory()
const RouterMiddleware = routerMiddleware(history)

export default function configureStore(initialState = {}) {
  const rootReducer = combineReducers({...reducers, ...initialState});
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    // applyMiddleware(sagaMiddleware, RouterMiddleware)
    composeEnhancers(applyMiddleware(sagaMiddleware, RouterMiddleware))
    ,
  );
  sagaMiddleware.run(mySaga);
  return store
}
