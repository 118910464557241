import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContactUsView from '../../views/ContactUsView'
import {  contactUsSelectors, contactUsActions} from '../../state/redux/contact_us'

const { getContactFirst, getEdit} = contactUsSelectors

const mapStateToProps = state => ({
  contact: getContactFirst(state),
  edit: getEdit(state)
})

const mapDispatchToProps = dispatch => ({
  contactUsActions: bindActionCreators(contactUsActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsView)
