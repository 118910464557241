import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import  createStore, { history } from './state/store';
import { routerReducer } from 'react-router-redux'
import App from './containers/AppContainer';

import * as serviceWorker from './serviceWorker';

const store = createStore({
  routing: routerReducer
})

const render = (RootApp) => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <RootApp history={history}/>
      </Provider>
    </AppContainer>,
    document.getElementById('root'),
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./containers/AppContainer', () => {
    render(App)
  })
}


serviceWorker.register();
