import React, { useState, useEffect } from "react";
import Button from "../../common/components/Button";
import CalculatorNav from "./CalculatorNav";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import './CalculatorForm.scss'

export default ({
  setStep,
  prevStep,
  nextStep,
  step,
  setFormInfo,
  formInfo,
  formStepStyle,
  calculatorActions
}) => {
  const [disabled, setDisabled] = useState(true);

  const sliders = [
    {
      img: "agricultural",
      title: "agricultura"
    },
    {
      img: "construction",
      title: "construcción"
    },
    {
      img: "industry",
      title: "industria"
    },
    {
      img: "service",
      title: "servicios"
    },
    {
      img: "trade",
      title: "comercio"
    }
  ];

  const galleryItems = sliders.map((item, i) => {
    const active = formInfo.rubro === item.title ? " active" : "";
    return (
      <div
        className={`img-wrapper ${active}`}
        key={`img-wrapper${i}`}
        onClick={() => validStepTwo(item.title)}
      >
        <img
          src={
            active !== ""
              ? `./images/calculator/${item.img}_white.svg`
              : `./images/calculator/${item.img}.svg`
          }
          alt={item.title}
        />
        <p>{item.title}</p>
      </div>
    );
  });

  const responsive = {
    450: { items: 1 },
    767: { items: 3 },
    1024: { items: 5 }
  };

  const validStepTwo = value => {
  
    calculatorActions.setValues({rubro:value})
    setFormInfo({ ...formInfo, rubro: value });
    setDisabled(false);
  };

  useEffect(() => {
    if (formInfo.rubro !== "") {
      setDisabled(false);
    }
  });

  return (
    <div
      class={`step ${formStepStyle}`}
      onKeyPress={e => {
        console.log('--------e', e);
        
        if (e.key === "Enter" && !disabled) {
          setStep(nextStep(step));
        }
      }}
    >
      <div className="step__nav">
        <CalculatorNav
          setStep={setStep}
          step={step}
          valid={!disabled}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      </div>
      <div className="step__content rubro">
        <div className="step__content__header">
          <p>Seleccioná tu rubro</p>
          <AliceCarousel
            dotsDisabled={false}
            buttonsDisabled={true}
            responsive={responsive}
            items={galleryItems}
          />
        </div>
        <div className="step__content__footer">
          <div className="step__content__footer__wrapper">
            <Button
              label="OK"
              type="contain"
              onClick={() => setStep(nextStep(step))}
              icon="check"
              iconLast={true}
              disabled={disabled}
            />
            <p>presióna ENTER</p>
          </div>
        </div>
      </div>
    </div>
  );
};
