/*
  REDUCER HISTORY
 */
import types from './types';
import { combineReducers} from 'redux';
import getSidebarNavItems from '../../../data/sidebar-nav-items';
import getNavBarItems from '../../../data/nav-bar-items'
const initialState = {
  menuVisible: false,
  navItems:getSidebarNavItems(),
  navBaritems:getNavBarItems(),
  active:'home'
}


const siderNavBar = (state = initialState, action) => {
  switch (action.type) {
      case types.TOGGLE_SIDEBAR:
        return {
          ...state,
          menuVisible: !state.menuVisible
        }
      case types.ACTIVE_MENU:
        return {
          ...state,
          active: action.payload
        }
      default:
        return state
  }
}


export default siderNavBar
