import types from './types'

const getContact = (payload) => ({
    type: types.GET_CONTACTUS.REQUEST,
    payload
 })
const createOrUpdate = (payload) => ({
    type: types.CREATE_OR_UPDATE.REQUEST,
    payload
})


const addValue = (payload) => ({
    type: types.ADD_VALUE,
    payload
})

const call = (payload)=> ({
    type: types.CALL.REQUEST,
    payload
})
const getCalls = ()=> ({
    type: types.GET_CALL.REQUEST
})

export default{
    getContact,
    createOrUpdate,
    addValue,
    call,
    getCalls
}