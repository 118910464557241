import React from 'react'
import {
    Card,
    CardBody,
    ButtonGroup,
    Button,
  } from "shards-react";
const Client = ({item,handleOnDragStart,index,edit, onDelete, onEdit  }) => {

    return (
        <React.Fragment>
            <a key={item.itemId} href={item.link} target="_blank" className="slider-link">
                <img className="slider-image h-50p" src={item.image} alt={item.title} onDragStart={handleOnDragStart}/> 
            </a>
            {edit ?
            <div className="blog-comments__actions mt-2">
                <ButtonGroup size="sm" className="">
                    
                    <Button theme="white" onClick={() => {onDelete(item.itemId)}}>
                    <span className="text-danger">
                        <i className="material-icons">clear</i>
                    </span>{" "}
                    Eliminar
                    </Button>
                    <Button theme="white" onClick={() => {onEdit(item.itemId)}}>
                    <span className="text-light">
                        <i className="material-icons">more_vert</i>
                    </span>{" "}
                    Editar
                    </Button>
                </ButtonGroup>
            </div> 
            :""}
        </React.Fragment>
    )
}

export default Client