import { createRequestTypes } from '../../../utils/parse'

const namespace = 'calculator'

const SET_VALUES = `${namespace}/SET_VALUES`
const VALIDATE_CUIT= createRequestTypes(`${namespace}/VALIDATE_CUIT`)
const CALCULATOR = createRequestTypes(`${namespace}/CALCULATOR`)
const GET_CALCULATOR = createRequestTypes(`${namespace}/GET_CALCULATOR`)
const GET_WEIGHT = createRequestTypes(`${namespace}/GET_WEIGHT`)
const SET_WEIGHT = createRequestTypes(`${namespace}/SET_WEIGHT`)
export default {
    SET_VALUES,
    VALIDATE_CUIT,
    CALCULATOR,
    GET_CALCULATOR,
    GET_WEIGHT,
SET_WEIGHT
}