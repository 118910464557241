import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import './style.scss'
class WhatIsIt extends Component {
  sendTo = to => {
    this.props.history.push(to);
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <section className="what-is-it">
        <Fade bottom>
          <div className="column img-what">
            <div className="bg-degrade">
              <p>
                Más de 20 años
                <br />
                apoyando a los
                <br />
                emprendedores
              </p>
            </div>
            <img src="./images/home/what-is-it-2.jpg" alt="what-it" />
          </div>
        </Fade>
        <Fade bottom>
          <div className="column column--info">
            <p className="title color-primary">
              ¿QUÉ ES UNA SOCIEDAD DE GARANTÍA RECÍPROCA?
            </p>
            <p className="description">
              Las Sociedades de Garantía Recíproca (SGR) son organizaciones
              privadas que dentro del marco de la SEPYME (Secretaría dentro del
              Ministerio de Producción de la Nación), tienen por objeto
              fundamental facilitar el acceso a Créditos a las Pequeñas y
              Medianas Empresas Argentinas, a través del otorgamiento de AVALES
              que funcionan como garantía para la obtención de Líneas de Crédito
              en Bancos, Mercados de Capitales o Créditos Comerciales con
              proveedores.
            </p>

            <p
              className="link"
              onClick={() => this.sendTo("/services")}
            >
              Conocer más sobre Tu Aval{" "}
              <i class="material-icons">arrow_right_alt</i>
            </p>
          </div>
        </Fade>
      </section>
    );
  }
}

export default withRouter(WhatIsIt);